.btn-container {
  background-color: black;
  max-width: fit-content;
  border-radius: 0.675rem;
}

.btn-component {
  padding: 0.3rem 3rem;
  background-color: black;
  background: linear-gradient(90deg, #ffd542 0%, #e8a111 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: Barlow;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 36px;
}

.btn-component.primary {
  border: 2px solid #e8a111;
  border-radius: 0.675rem;
}

.btn-component.secondary {
  border: none;
}
