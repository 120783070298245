@import "../../../styles/variables";

// @media (max-width: $ssm) {}
// @media (min-width: $mmd) {}
// color: $white;
.Schools {
  .-label {
    position: absolute;
    right: 0;
    top: 0;
    background: $white;
    padding: 15px;
    transform: translate(24px, 20px);
    font-size: 16px;
    line-height: 26px;

    @media (min-width: $md) {
      font-size: 24px;
      line-height: 34px;
    }
  }
}
