@import "../../../styles/variables";

// HEADING

.font-family-barlow {
  font-family: $fontBarlow !important;
}
.heading {
  font-size: 24px;
  line-height: 34px;
  color: $black;
  // font-family: $fontBarlow;
  // font-weight: $fw700;
  // background : red;
  // font-weight: $fw500;
  // RECORD AUDIO BASED CHANGE
  font-weight: $fw600;
  font-family: $fontLora;

  @media (min-width: $md) {
    font-size: 40px;
    line-height: 50px;
  }
}

.h1Lg {
  font-size: 30px;
  line-height: 40px;
  color: $black;
  font-family: $fontBarlow;
  font-weight: $fw700;

  @media (min-width: $md) {
    font-size: 56px;
    line-height: 66px;
  }
}

.h1Md {
  font-size: 20px;
  line-height: 30px;
  color: $white;
  font-weight: $fw600;
  font-family: $fontLora;

  @media (min-width: $md) {
    font-size: 30px;
    line-height: 40px;
  }
}

// H4HEADING
.h4heading {
  font-size: 20px;
  line-height: 30px;
  color: $black;
  // font-family: $fontBarlow;
  // RECORD AUDIO BASED CHANGE
  font-family: $fontLora;
  font-weight: $fw700;

  @media (min-width: $md) {
    font-size: 24px;
    line-height: 34px;
  }
}

// H4HEADING
.h3heading {
  font-size: 24px;
  line-height: 34px;
  color: $black;
  // font-family: $fontBarlow;
  // RECORD AUDIO BASED CHANGE
  font-family: $fontLora;
  font-weight: $fw700;

  @media (min-width: $md) {
    font-size: 32px;
    line-height: 42px;
  }
}

// H6HEADING
.h5heading {
  font-size: 16px;
  line-height: 26px;
  font-weight: $fw600;
}

// H6HEADING
.h6heading {
  font-size: 12px;
  line-height: 14px;
  color: $black;
  font-weight: $fw100;
}

// H7HEADING
.h7heading {
  font-size: 16px;
  line-height: 26px;
  color: $black;
  font-weight: $fw500;
  text-transform: capitalize;

  @media (min-width: $md) {
    font-size: 18px;
    line-height: 28px;
  }
}

// DESC
.desc {
  font-size: 16px;
  line-height: 26px;
  color: $black08;
  font-family: $fontBarlow;

  @media (min-width: $md) {
    font-size: 18px;
    line-height: 28px;
  }
}
.descBlack {
  font-size: 16px;
  line-height: 26px;
  color: $black;
  font-family: $fontBarlow;

  @media (min-width: $md) {
    font-size: 18px;
    line-height: 28px;
  }
}

.descSmall {
  font-size: 14px;
  line-height: 24px;
  color: $white08 !important;
  font-family: $fontBarlow;
}

// ANCHORTAG
.AnchorTag {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  font-weight: $fw500;
  color: $white;
  transition: $transition;

  &:hover {
    transition: $transition;

    svg path {
      transition: $transition;
      opacity: 1;
    }
  }
}

// HASHTAGTITLE
.HashTagTitle {
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0;
  font-weight: $fw900;
  color: $black08;

  @media (min-width: $lg) {
    font-size: 90px;
    line-height: 100px;
  }
}

// ANCHORBUTTON
.AnchorButton,
.AnchorButtonIcon,
.AnchorGradientButton {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  background: $black;
  color: $white;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid $black;
  transition: $transition;
  padding: 10px 16px;
  font-weight: $fw600;

  @media (min-width: $lg) {
    padding: 16px 40px;
    font-size: 16px;
    line-height: 26px;
  }

  &:hover {
    color: $black;
    background: $white;
    border: 1px solid $black;
    transition: $transition;
    border-radius: 0;

    svg path {
      fill: $black;
    }
  }

  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    background: #fff;
    transition: all cubic-bezier(0.37, 0.68, 0.27, 0.71) 0.4s;
    z-index: -1;
  }

  &:hover::after {
    width: 100%;
  }
}

// AnchorGradientButton
.AnchorGradientButton {
  color: $orange;
  // background             : -webkit-linear-gradient($orange, #ECC53B);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  border-radius: 4px;
  border: 1px solid $orange;

  @media (max-width: $lg) {
    padding: 10px 10px;
  }
}

.AnchorGradientLink {
  color: $orange;
  background: $transparent;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 24px;
  transition: $transition;
  font-weight: $fw600;

  &:focus,
  &:hover {
    transition: $transition;
    text-decoration: underline !important;
  }
}
