@import "../../styles/fonts";

.BlogsList,
.section-other-blogs {
    .blog.card {
        background-color: #fff !important;
        border: 2px solid rgba(0, 0, 0, 0.05);
        font-family: "BentonSansBook";
        border-radius: 24px !important;
        .img-wrapper {
            border-radius: 18px 18px 0px 0px;
            overflow: hidden;
            
            .blog-card-img {
                height: 12.5rem;
            }

            .label {
                position: absolute;
                top: 0;
                left: 0;
                padding: 9px;
                margin: 8px 0 0 8px;
                color: #000;
                color: #fff;
                font-family: "BentonSansBold";
                text-transform: uppercase;
                font-size: 0.6rem;
                font-style: normal;
                font-weight: 400;
                line-height: 19.737px; /* 216.667% */
                border-radius: 6.073px;
                border-top-left-radius: 18px;
                background: rgba(25, 15, 15, 0.12);
            }
        }
        a {
            display: flex;
            flex-direction: column;
            flex: 1 auto;
        }
        .card-body {
            font-family: "BentonSansBook";
            padding: 1.5rem 0.5rem 0.5rem 0.5rem;
            font-size: 0.75rem;
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            .card-title {
                color: #000;
                line-height: 2rem;

                font-family: "BentonSansBold";
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 27px */
            }
            .card-text {
                color: rgba(0, 0, 0, 0.5);
                font-family: $fontRegular !important;
                font-size: 0.75rem;
            }
        }
        .card-footer {
            color: #000;
        }
        @media (min-width: 576px) {
            &:hover {
                border: 2px solid rgba(255, 255, 255, 0.2);
                background: rgba(255, 255, 255, 0.1) !important;
                box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.12);
            }
        }
        .separator {
            width: 20px;
            height: 3px;
            background: linear-gradient(
                to right,
                var(--color-primary-1),
                var(--color-primary-2)
            );
        }

        .date {
            font-size: 0.625rem;
            font-weight: 400;
        }

        .time {
            font-size: 0.625rem;
            font-weight: 400;
        }

        .card-footer {
            background: none;
            border: none;
        }
        .name {
            font-size: 1.25rem;
            font-weight: 600;
        }

        .desc {
            font-size: 0.75rem;
            font-weight: 400;
            opacity: 0.8;
        }
    }
}
