@import "../../../styles/variables";
@import "../../../styles/fonts";
.section-other-blogs {
    @media (max-width: 575px) {
        padding-bottom: 0rem !important;
    }
    @media (min-width: 1366px) {
        padding: 0;
    }
    .sub-heading {
        font-family: $fontBold !important;
        font-size: 2rem;
        padding-bottom: 1.5rem;
    }
    .carousel {
        .thumbs-wrapper {
            display: none;
        }
        .slide {
            text-align: unset;
        }
        .control-dots {
            top: -4rem !important;
            z-index: -1;
            .dot {
                background: rgba(0, 0, 0, 0.5);
            }
            .dot.selected {
                width: 2rem;
                border-radius: 25%;
                background-color: $black;
            }
        }
        .slider-wrapper {
            box-shadow: none;
            .card {
                margin: auto !important;
                width: min-content;
            }
        }
    }
    .card-deck {
        background: $white;
        gap: 2rem;
    }

    .blog {
        width: 33%;
    }

    .summary {
        font-size: 1.125rem;
    }

    .name {
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.75rem;
    }

    .desc {
        font-size: 1rem;
        font-weight: 400;
        opacity: 0.8;
    }

    .separator {
        width: 20px;
        height: 3px;
        background: linear-gradient(
            to right,
            var(--color-primary-1),
            var(--color-primary-2)
        );
    }

    .date {
        font-size: 0.75rem;
        font-weight: 400;
        // letter-spacing: 0.1rem;
    }

    .time {
        font-size: 0.75rem;
        font-weight: 400;
        // letter-spacing: 0.1rem;
    }

    .card-footer {
        background: none;
        border: none;
    }

    .margin {
        margin-top: 2.5rem;
    }
}
