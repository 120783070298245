.app-container {
    position: relative;

    .section {
        padding: 4.5rem 0;
        position: relative;
        overflow: hidden;

        &--paddingBottom0 {
            padding-bottom: 0;
        }
    }

    .bgPattern {
        .section {
            // padding: 3.5rem 0;
            padding: 1.5rem 0;

            @media (min-width: $sm) {
                padding: 3.8rem 0;
            }

            @media (min-width: $lg) {
                padding: 5rem 0;
            }
        }
    }

    .content {
        max-width: 1148px;
        margin: 0 auto;
        padding: 0 2rem;
        @media (max-width: 575px) {
            padding: 0 1rem;
        }
    }

    .sub-heading {
        font-size: 2.75rem;
        margin: 0 0 2rem 0;
    }
}

a {
    &:hover {
        text-decoration: none !important;
    }
}

.font-benton-book {
    font-family: "BentonSansBook" !important;
}
.font-benton-bold {
    font-family: "BentonSansBold" !important;
}
