@mixin btn($bg, $border) {
  padding: 0 2rem;
  height: 48px;
  font-size: 1.125rem;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  text-align: center;
  border-radius: 16px;
  background: $bg;
  border: 2px solid $border;
  transition: all ease-in 200ms;
}

@mixin btn-text($bg) {
  background: $bg;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.app-btn--big {
  font-size: 1.5rem !important;
  height: 56px !important;
  padding: 0 3rem !important;
}

.app-btn--small {
  font-size: 14px !important;
  line-height: 14px !important;
  // height     : auto !important;
  // padding: 1rem !important;
}

.app-btn {
  &--solid--primary {
    @include btn(var(--color-dark-1), var(--color-dark-1));

    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 100%;
      background: #fff;
      transition: all cubic-bezier(0.37, 0.68, 0.27, 0.71) 0.4s;
      z-index: -1;
    }

    &:hover::after {
      width: 100%;
    }

    .app-btn-text {
      @include btn-text(
        linear-gradient(
          to right,
          var(--color-primary-1) 0%,
          var(--color-primary-2) 100%
        )
      );
    }

    // &:hover {
    // transform: translateY(-3px);
    // box-shadow: 3px 3px 1px 1px var(--color-light-1);
    // }
  }

  &--hollow--dark {
    @include btn(transparent, var(--color-dark-1));

    .app-btn-text {
      @include btn-text(var(--color-dark-1));
    }

    // &:hover {
    //   background: var(--color-dark-1);
    //   .app-btn-text {
    //     background: linear-gradient(
    //       to right,
    //       var(--color-primary-1) 0%,
    //       var(--color-primary-2) 100%
    //     );
    //   }
    // }
  }

  &--hollow--white {
    @include btn(transparent, var(--color-white));

    .app-btn-text {
      @include btn-text(var(--color-white));
    }

    // &:hover {
    //   border-color: var(--color-primary-2);
    //   .app-btn-text {
    //     background: linear-gradient(
    //       to right,
    //       var(--color-primary-1) 0%,
    //       var(--color-primary-2) 100%
    //     );
    //   }
    // }
  }

  &--hollow--primary {
    @include btn(transparent, var(--color-primary-2));

    .app-btn-text {
      @include btn-text(
        linear-gradient(
          to right,
          var(--color-primary-1) 0%,
          var(--color-primary-2) 100%
        )
      );
    }

    // &:hover {
    //   border-color: var(--color-white);
    //   .app-btn-text {
    //     background: var(--color-white);
    //   }
    // }
  }
}

.-btn-link {
  color: $orange;
  font-weight: $fw900;
}
