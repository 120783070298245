.modal-content {
    border-radius: 3px;
    outline: 0;
    padding: 14px;

    @media (min-width: 992px) {
        padding: 36px;
    }

    .form-label {
        font-weight: 600;
        line-height: 14px;
        color: $black17;
    }

    .form-group {
        @media (min-width: $mmd) {
            margin-bottom: 2.3rem;
        }
    }

    .close,
    .close span {
        color: #0b0223 !important;
        font-size: 37px;
        opacity: 1;
    }

    .modal-header {
        padding-top: 4px;
        margin-bottom: 12px;
    }

    .modal-header,
    .modal-body,
    .modal-footer {
        border: none !important;
    }

    .modal-footer {
        .react-reveal {
            width: 100%;
        }
    }
    @media (max-width: $smd) {
        // padding: 15px;
        // height: 100vh;
        height: 100%;
        margin-top: 1.25rem;
    }
}

.modal680 {
    max-width: 680px;
    text-align: center;
    &--small {
        max-width: 500px;
    }
    @media (max-width: $smd) {
        margin: 0 auto;
        max-width: 90%;
        width: 100%;
    }

    .modal-content {
        @media (max-width: $smd) {
            // padding: 15px;
            // height: 100vh;
            // height: 80vh;
            height: 100%;
            margin-top: 3rem;
            padding: 3rem 15px;
            text-align: center;
        }

        position: relative;

        &::after {
            content: "";
            position: absolute;
            z-index: 0;
            bottom: 0;
            height: 68px;
            width: 100%;
            right: 0;
            left: 0;
            background: url("../images/Common/HrGrassPattern.svg") repeat-x;
        }
    }

    .close span,
    .close {
        font-size: 34px !important;
    }

    .close {
        position: absolute !important;
        right: 30px !important;
        top: 30px !important;
        padding: 0 !important;
        margin: 0 !important;

        @media (max-width: $smd) {
            top: 20px !important;
        }
    }
}

.customModal {
    .modal-dialog {
        @media (min-width: 576px) {
            max-width: 600px;
        }
    }
}
.disclaimer {
    .modal-content {
        padding: 0;

        & > div {
            padding: 16px;
        }
    }
    .modal-footer {
        padding: 16px;
        justify-content: center;
        @media (min-width: 768px) {
            justify-content: end;
        }
    }
    .modal-dialog {
        @media (min-width: 768px) {
            max-width: 750px;
        }
        @media (min-width: 1024px) {
            max-width: 1000px;
        }

        .modal-content {
            border-radius: 20px;
        }

        .heading {
            width: 100%;
            font-size: 1.25rem;
            display: grid;
            gap: 8px;
            grid-template-columns: 1fr auto 1fr;
            align-items: center;
            justify-content: center;

            @media (min-width: 768px) {
                font-size: 1.5rem;
                gap: 16px;
            }

            .line-start {
                height: 1px;
                width: 100%;
                background-image: linear-gradient(
                    to right,
                    #00000000 0%,
                    #000 100%
                );
            }

            .line-end {
                width: 100%;
                height: 1px;
                background-image: linear-gradient(
                    to right,
                    #000 0%,
                    #00000000 100%
                );
            }

            .rounded-full {
                border-radius: 99999px;
            }
        }
    }
    .submit-btn {
        border-radius: 0.5rem !important;
        border-color: $black !important;
        line-height: 1rem;
        max-height: 2.5rem;
        background-color: $black !important;
        .btn-text {
            font-family: "BentonSansMedium";
            font-size: 0.875rem !important;
            background: linear-gradient(90deg, #ffd542 0%, #e8a111 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        @media (min-width: 576px) {
            padding: 0.8125rem 1.35rem !important;
        }
    }
    .cancel-btn {
        background-color: $white !important;
        color: $black !important;
        border-radius: 0.5rem !important;
        border-color: $black !important;
        padding: 0.2125rem 1.35rem !important;
        .btn-text {
            font-family: "BentonSansMedium";
            font-size: 0.875rem;
            color: $black;
        }
        @media (min-width: 576px) {
            padding: 0.1rem 1.35rem !important;
        }
    }
    .disclaimer-header {
        padding: 0;

        // button {
        //     padding-left: 0;
        //     padding-right: 0;
        // }
    }
    .disclaimer-body {
        display: grid;
        height: 100%;
        max-height: 50vh;
        overflow: scroll;
        @media (max-width: 768px) {
            padding: 0;
        }
    }
    .disclaimer-heading {
        font-size: 1.5rem;
        @media (max-width: 768px) {
            font-size: 1rem;
        }
    }
    .disclaimer-content {
        font-size: 14px;
        // @media (max-width: 768px) {
        //     font-size: 0.7rem;
        // }
    }

    .modal-header {
        @media (max-width: 768px) {
            padding: 0;
            margin: 0;
        }
    }
    .close span {
        font-weight: 100;
        @media (max-width: 768px) {
            font-size: 1.6rem !important;
        }
    }
    .close {
        @media (max-width: 768px) {
            margin: -1.5rem -1rem -1rem auto;
        }
    }
}

.modal-dialog {
    top: 50%;
    transform: translateY(-50%) !important;
    @media (max-width: $smd) {
        margin: auto;
        max-width: 90%;
        width: 100%;
    }
}
