@import "../../../styles/variables";

.shareLinkBlock {
  position: fixed !important;
  top: 40%;
  height: 100vh;
  padding: 0 !important;
  left: 12px;
  z-index: 1;

  @media (min-width: 1536px) {
    left: 52px;
  }

  @media (min-width: 1920px) {
    left: 4%;
  }

  display: none !important;
}

.shareLinkBlockList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.-share-link {
  margin: 12px 0;
}

.shareLinkBlock.-only-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative !important;
  height: auto !important;
  left: 0;
  top: 0;

  .shareLinkBlockList {
    flex-direction: row;
  }

  .-share-link {
    margin: 12px;
  }
}
