@import "../../../styles/variables";

// @media (max-width: $ssm) {}
// @media (min-width: $mmd) {}
// color: $white;
.OutdoorSeating {
  .-label {
    position: absolute;
    right: 0;
    top: 0;
    background: $white;
    padding: 15px;
    transform: translate(4px, 20px);
    font-size: 16px;
    line-height: 26px;

    @media (max-width: $smd) {
      transform: translate(-10px, 10px);
    }

    @media (min-width: $md) {
      font-size: 24px;
      line-height: 34px;
    }
  }

  &__image {
    height: 550px;
    object-fit: cover;
    // background: red;

    @media (max-width: $md) {
      height: 430px;
    }
  }

  .ArrowButtonBlock {
    width: 100%;
    transform: translate(0, -30vh);
    background: $transparent !important;

    @media (max-width: $md) {
      transform: translate(0, -2vh);
      display: none;
    }

    &__Arrow {
      padding: 27px;

      @media (max-width: $md) {
        transform: translate(0, -12vh);
      }
    }
  }

  .swiper-pagination {
    &.swiper-pagination-OutdoorSeating {
      position: absolute;
      left: 0;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      width: 100%;
      right: 0;
      display: block;
      bottom: 30px;
      // height     : 100px;

      @media (max-width: $mmd) {
        // height : 160px;
        display: block;
        bottom: 40px;
      }
    }
  }
}

.OutdoorSeating.OutdoorSeating--small {
  .ArrowButtonBlock {
    width: 100%;
    transform: translate(0, 4vh);
    background: transparent !important;
    justify-content: center;
  }

  .ArrowButtons {
    .swiper-pagination {
      display: none !important;
      @media (max-width: 767px) {
        display: block !important;
        bottom: 0;
      }
    }
  }
  .ArrowButtonBlock__Arrow {
    padding: 27px 20px;
    @media (max-width: 1200px) {
      display: none !important;
    }
  }
}
