@import "../../styles/variables";
.section-showcase {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;

  .name {
    font-size: 2rem;
  }
  .sub-heading {
    font-family: "BentonSansBold" !important;
    text-align: center !important;
    font-size: 2rem !important;
    padding: 0.25rem 0 !important;
    margin-bottom: 3.5rem;
  }

  .project-row {
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
  }

  .feature-label {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-family: "Lora", serif;
  }

  .feature-name {
    font-size: 1.125rem;
    opacity: 0.6;
  }

  .desc {
    font-size: 1.125rem;
  }

  .separator {
    min-width: 1px;
    min-height: 2rem;
    margin: auto 2.8rem;
  }
}
