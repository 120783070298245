.ms-Breadcrumb {
  //
  &--blog {
    ol {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding: 1rem 0;
    }
  }
}

.breadcrumb {
  margin-top: 4rem;
  padding: 2rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;

  @media (min-width: 992px) {
    padding: 1.5rem 0;
  }

  a,
  .breadcrumb-item.active,
  .breadcrumb-item + .breadcrumb-item::before {
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    color: black;

    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .breadcrumb-item.active {
    color: $orange;

    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
}
