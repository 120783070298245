$benton-font-path: "../fonts";

@font-face {
    // This is parsed as a normal function call that takes a quoted string.
    src: url("#{$benton-font-path}/BentonSans Bold.otf") format("opentype");

    font-family: "BentonSansBold";
    font-weight: 400;
}
@font-face {
    // This is parsed as a normal function call that takes a quoted string.
    src: url("#{$benton-font-path}/BentonSans Book.otf") format("opentype");

    font-family: "BentonSansBook";
    font-weight: 400;
}

@font-face {
    // This is parsed as a normal function call that takes a quoted string.
    src: url("#{$benton-font-path}/BentonSans Medium.otf") format("opentype");

    font-family: "BentonSansMedium";
    font-weight: 400;
}

$fontBold: "BentonSansBold";
$fontRegular: "BentonSansBook";
$fontMedium: "";
