@import "../../../styles/variables";

// @media (max-width: $ssm) {}
// @media (min-width: $mmd) {}
// color: $white;

.TranquilCourt-Engrace {
    position: relative;

    @media (max-width: $ssm) {
        display: flex;
        flex-direction: column-reverse;
    }

    // &__desc {
    // }

    // &__title {
    // }

    // &__desc {
    // }
    .amenities {
        position: relative;
        margin-left: 80%;
    }
    &__image {
        @media (max-width: $ssm) {
            position: relative;
            width: 100%;
            margin-bottom: 3rem;
        }

        @media (min-width: $sm) {
            position: absolute;
            right: 0;
            width: 60%;
            bottom: 0;
            height: 560px;
            // height: 100vh;
            object-fit: contain;
        }
    }

    &__imageLarge {
        @media (max-width: $ssm) {
            width: 100%;
        }

        @media (min-width: $sm) {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 560px;
            // height: 100vh;
            object-fit: contain;
            width: 72%;
        }

        @media (min-width: $mmd) {
            width: 68%;
            height: 70vh;
        }

        @media (min-width: $lg) {
            width: 64%;
            height: 75vh;
        }

        @media (min-width: $extrLg) {
            width: 68%;
            height: 87vh;
        }

        @media (min-width: 2000px) {
            height: 540px;
            width: 66%;
        }
    }

    &__imageSmall {
        @media (max-width: $ssm) {
            height: 123px;
            width: 243px;
            transform: translate(-70px, -70px);
        }

        @media (min-width: $sm) {
            position: relative;
            right: 0;
            bottom: 0;
            width: 238px;
            height: 238px;
            object-fit: contain;
            transform: translate(-120px, -140px);
        }

        @media (min-width: $mmd) {
            transform: translate(-160px, -50px);
        }

        // @media (min-width: $extrLg) {
        //   transform: translate(-70px, 10px);
        // }
    }
}

.TranquilCourtImage {
    // @media (max-width: $ssm) {
    // }

    @media (min-width: $sm) {
        position: absolute;
        left: 0;
        text-align: center;
        right: 0;
        margin: 0 auto;
        bottom: 0;
        width: 100%;
        height: 123px;
        transform: translate(170px, 0);
    }

    @media (min-width: 2000px) {
        height: 93px;
    }

    // @media (min-width: $extrLg) {
    //   transform: translate(135px, 0);
    // }

    &::after {
        @media (max-width: $ssm) {
            content: "";
            position: absolute;
            text-align: center;
            left: 95px;
            // top: 37%;
            top: 27%;
            width: 123px;
            height: 123px;
            // background: url("../../../images/SoulTree/TranquilCourt/pattern.svg")
            // no-repeat center;
        }

        @media (min-width: $sm) {
            content: "";
            position: absolute;
            left: 0;
            text-align: center;
            right: 0;
            margin: 0 auto;
            bottom: 0;
            width: 123px;
            height: 123px;
            // background: url("../../../images/SoulTree/TranquilCourt/pattern.svg")
            //   no-repeat center;
        }
    }
}

.TranquilCourtList {
    padding-left: 2rem;

    li {
        position: relative;

        &::after {
            // width: 4px;
            // height: 4px;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            // background: $black02;
            transition: $transition;
            transform-origin: left;
            border-radius: 50%;
            // transform: translate(-20px, 14px);

            background: $black05;
            width: 7px;
            height: 7px;
            transform: translate(-20px, 13px);

            @media (max-width: 992px) {
                transform: translate(-18px, 10px);
            }
        }
    }
}
