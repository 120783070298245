@import "../../../styles/variables";

.md-masterplan-svg {
  height: 300px;
  width: 100%;

  @media (min-width: $md) {
    height: 400px;
    width: 800px;
  }

  @media (min-width: $lg) {
    height: 500px;
    width: 1000px;
  }
}

.st0 {
  fill: #444;
}

.st1 {
  fill: #f2f2f2;
}

.st2 {
  fill: #f7f7f7;
  stroke: #e6e6e6;
  stroke-width: 0.25;
  stroke-linejoin: round;
}

.st3 {
  fill: none;
  stroke: #e6e6e6;
  stroke-width: 0.25;
  stroke-linejoin: round;
}

.st4 {
  fill: #fff;
  stroke: #e6e6e6;
  stroke-width: 0.25;
  stroke-linejoin: round;
}

.st5 {
  fill: #fff;
  stroke: #e6e6e6;
  stroke-linejoin: round;
}

.st6 {
  fill: #fff;
}

.st7 {
  fill: #fff;
  stroke: #000;
  stroke-width: 0.25;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st8 {
  fill: #3b3b3b;
}

.st9 {
  fill: #fff;
  stroke: #000;
  stroke-width: 0.25;
  stroke-linejoin: round;
}

.st10 {
  fill: none;
  stroke: #000;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}

.Block {
  transition: 0.3s ease-in-out;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 1);
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);

    .st7,
    .st9 {
      fill: #cedc3e;
    }
  }

  &:focus {
    outline: none;
  }
}

.Shadow {
  filter: blur(2px);
  opacity: 0.5;
}

.tooltip-fig {
  transition: 0.3s ease-in-out;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 50%;

  @media (min-width: $md) {
    height: 100px;
    width: 100px;
  }
}

.__react_component_tooltip {
  background: transparent !important;
  opacity: 1 !important;
  padding: 0 !important;

  &::after {
    border-top-color: black !important;
  }
}

.Top,
.Bottom,
.Right {
  position: absolute;
  transition: 0.3s ease-in-out;
  z-index: 1;

  @media (max-width: 1024px) {
    width: 60px;
    height: 60px;
  }

  @media (min-width: 1025px) {
    width: 123px;
    height: 123px;
  }

  svg {
    @media (max-width: 1024px) {
      // width : 123px;
      // height: 123px;
    }
  }

  &:hover {
    transition: 0.3s ease-in-out;
    // transform : translateY(-1px);

    .HoverChangeColor path {
      transition: 0.3s ease-in-out;
      // stroke    : #03a9f4;
      stroke: rgba($black, 0.2);
      // fill: green;
      fill: #cedc3e;
    }

    .HoverChangeColor {
      transition: 0.3s ease-in-out;
      // transform    : translateY(-1px);
      // box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    }
  }
}

.HoverChangeColor {
  transition: 0.3s ease-in-out;
}

.H100vh {
  height: 80vh !important;

  @media (min-width: 768px) {
    height: 100vh !important;
  }

  @media (min-width: 1024px) {
    // height: 100vh !important;
  }
}

.Bottom {
  // background: red;
  right: 56%;
  top: 266px;
  transform: scale(0.35);

  @media (min-width: 768px) {
    right: 62%;
    top: 280px;
    transform: scale(0.75);
  }

  @media (min-width: 992px) {
    right: 62%;
    top: 280px;
    transform: scale(0.84);
  }

  @media (min-width: 1024px) {
    right: 62%;
    top: 280px;
    transform: scale(0.84);
  }

  @media (min-width: 1270px) {
    transform: scale(0.9);
    right: 54.5%;
    top: 278px;
  }

  @media (min-width: 1300px) {
    right: 54.5%;
    top: 278px;
  }

  @media (min-width: 1440px) {
    right: 54.5%;
    top: 278px;
  }

  @media (min-width: 1536px) {
    //
  }

  @media (min-width: 1920px) {
    right: 55%;
    top: 278px;
  }
}

.Right {
  // background: yellow;
  right: 34%;
  top: 205px;
  transform: scale(0.5);

  @media (min-width: 768px) {
    right: 33%;
    top: 166px;
    transform: scale(0.9);
  }

  @media (min-width: 992px) {
    transform: scale(0.84);
    right: 33%;
    top: 166px;
  }

  @media (min-width: 1024px) {
    transform: scale(1);
    right: 33%;
    top: 162px;
  }

  @media (min-width: 1270px) {
    right: 29%;
    top: 170px;
  }

  @media (min-width: 1356px) {
    right: 29%;
    top: 170px;
  }

  @media (min-width: 1440px) {
    right: 29%;
    top: 168px;
  }

  @media (min-width: 1536px) {
    //
  }

  @media (min-width: 1920px) {
    right: 28%;
    top: 165px;
  }
}

.Top {
  // background: red;
  // left     : 15%;
  // top      : 150px;

  left: 13%;
  top: 143px;
  transform: scale(0.4);

  @media (min-width: 768px) {
    left: 24%;
    top: 73px;
    transform: scale(0.75);
  }

  @media (min-width: 992px) {
    left: 25%;
    top: 54px;
    transform: scale(0.84);
  }

  @media (min-width: 1024px) {
    left: 25%;
    top: 54px;
    transform: scale(0.84);
  }

  @media (min-width: 1270px) {
    transform: scale(0.9);
    left: 27%;
    top: 46px;
  }

  @media (min-width: 1300px) {
    left: 27%;
    top: 46px;
  }

  @media (min-width: 1440px) {
    left: 27%;
    top: 46px;
  }

  @media (min-width: 1536px) {
    //
  }

  @media (min-width: 1920px) {
    left: 27%;
    top: 39px;
  }
}

.Base1 {
  width: 100%;
  height: 570px;
}

.mt50 {
  @media (max-width: 767px) {
    margin-bottom: -43px;
  }
}

.MasterPlan {
  &__label {
    font-weight: $fw100;
    text-transform: uppercase;
    font-family: $fontBarlow;
    letter-spacing: 3px;

    @media (min-width: $md) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__info {
    font-weight: $fw700;
    text-transform: lowercase;
  }
}

.ulDivider {
  position: relative;

  &::after {
    width: 2px;
    height: 65%;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    transform: translateX(-10px);
    background: $black02;

    @media (min-width: $lg) {
      width: 4px;
      height: 50%;
      transform: translateX(-20px);
    }
  }
}

//  MasterPlanDOwnloadItems
.MasterPlanDOwnloadItems {
  &__icon {
    width: 30px;
    height: 30px;

    @media (min-width: $lg) {
      width: 48px;
      height: 48px;
    }
  }

  &__downloadIcon {
    width: 20px;
    height: 20px;

    @media (min-width: $lg) {
      width: 24px;
      height: 24px;
    }
  }
}
