@import "../../../styles/variables";

// @media (max-width: $ssm) {}
// @media (min-width: $mmd) {}
// color: $white;

$height553px: 553px;
$height400px: 400px;
$height70vh: 70vh;
$height638px: 638px;
$height500px: 500px;

.BlocksSlider {
  .-image {
    width: 100%;
    // max-height: 220px;

    max-height: 180px;
    object-fit: cover;
    height: 180px;
  }

  .-sold-out,
  .-title,
  .-desc,
  .price {
    color: $black;
  }

  .-sold-out-block {
    position: relative;
  }

  .-sold-out {
    position: absolute;
    background: rgba($white, 0.7);
    text-align: center;
    font-size: 40px;
    line-height: 50px;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
  }

  .-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: $fwBold;
  }

  .-desc,
  .-label {
    font-size: 14px;
    line-height: 24px;
  }

  .-price {
    font-size: 18px;
    line-height: 28px;
    font-weight: $fwBold;
    color: $black;
  }

  .-label {
    color: $orange;
  }
}

.Blocks-Container {
  .ArrowButtonBlock {
    right: 0;
    margin: 0 auto;
    background: $transparent;

    @media (min-width: $mmd) {
      width: 100%;
      transform: translate(0, -35vh);
    }

    &__Arrow {
      @media (min-width: $mmd) {
        padding: 0;
      }
    }

    &__ArrowPrevBlocks {
      @media (min-width: $lg) {
        transform: translate(-40px, 50%);
      }
    }

    &__ArrowNextBlocks {
      @media (min-width: $lg) {
        transform: translate(40px, 50%);
      }
    }
  }

  &--ClubsContainter {
    // position: absolute !important;
    width: 100%;
    bottom: 0;
    // z-index: 1;
    overflow: hidden !important;
    min-width: 100%;
    max-width: 100%;
    z-index: 2;
  }
}

.-blocks-card-block {
  box-shadow: $boxShadow;
  margin-bottom: 32px;
  border-radius: 4px;
  transition: $transition6;

  &:hover,
  &:focus {
    box-shadow: $boxShadowDark;
    transition: $transition6;
  }
}

.-blocks-block {
  padding: 24px;

  li:last-child {
    padding-bottom: 0;
  }

  &__list {
    padding-bottom: 16px;

    span {
      display: block;
    }
  }
}
