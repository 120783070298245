@import "../../../styles/variables";
.blog-detail-banner {
    position: relative;
    .progress-bar {
        z-index: 10000;
        position: fixed !important;
        top: 4.75rem;
        background-color: #111111;
        div {
            top: unset !important;
        }
    }
    .fixed-background {
        transition: height 0.3s;

        // position: fixed;
        position: absolute;
        height: 12rem;
        top: 0rem;
        width: 100vw;
        background-color: #111111;
        z-index: 1;
        @media (max-width: 575px) {
            height: 7.5rem;
        }
    }
    .blog-detail-banner-container {
        padding-top: 3rem;
        @media (max-width: 575px) {
            padding: 2rem;
        }

        .content-separator {
            width: 2rem;
            height: 2px;
            background: linear-gradient(90deg, #ffd542 0%, #e8a111 100%);
        }
        transition: height 0.2s;
        display: flex;
        .banner-wrapper {
            @media (max-width: 575px) {
                padding-left: 0;
                padding-right: 0;
            }
            padding-bottom: 3rem;
            .blog-header {
                .blog-title {
                    font-family: "BentonSansBold";
                    font-size: 1.5rem;
                    font-weight: 400;
                    @media (max-width: 575px) {
                        font-size: 1.125rem;
                    }
                }
            }
            .blog-desc {
                font-family: "BentonSansBook";
                font-size: 0.875rem;
                color: rgba(0, 0, 0, 0.8) !important;
                line-height: 150% !important;
            }
            .banner-image {
                width: 100%;
                img {
                    z-index: 2;
                    background-color: white;
                    top: 20%;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    border-radius: 1.25rem;
                    width: 100%;
                    // height: 100%;
                    // max-height: 24rem;
                    height: 23.4375rem;
                    object-fit: cover;
                    object-position: center;
                    @media (max-width: 575px) {
                        height: 9.25rem;
                    }
                }
            }
        }
    }

    // &.collapsed {
    //     margin-bottom: 30rem;

    //     .fixed-background {
    //         height: 8px;
    //     }
    //     .blog-detail-banner-container {
    //         @media (max-width: 575px) {
    //             .blog-meta {
    //                 display: none !important;
    //             }
    //         }

    //         z-index: 2;
    //         position: fixed;
    //         top: 5rem;
    //         left: 50%;
    //         transition: transform 0s;
    //         transform: translate(-50%);
    //         .banner-wrapper {
    //             display: none;
    //             gap: 2rem;
    //             height: 8rem;
    //             align-items: center;
    //             animation: slideDown 0.4s;

    //             .banner-image img {
    //                 height: 6rem;
    //                 border-radius: 0.5rem;
    //             }
    //             .blog-header {
    //                 margin: 0;
    //                 color: #858585;

    //                 .blog-title {
    //                     color: white;
    //                     mix-blend-mode: difference;
    //                     font-size: 1.5rem;
    //                     line-height: 2rem;
    //                     font-weight: 500;
    //                     margin: 0 !important;
    //                 }
    //                 .blog-desc {
    //                     color: rgba(255, 255, 255, 0.8) !important;
    //                 }
    //             }
    //         }
    //     }
    // }
}

// .blog-detail-banner.collapsed + .blog-detail-container {
//     position: relative;
//     top: 12rem;
// }

@keyframes slideDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

// @media (max-width: 575px){
//   .banner-image {
//     img{

//     }
//   }
// }
