@import "../../styles/variables";
@import "../../styles/fonts";

.project-card {
    cursor: pointer;
    border-radius: 28px !important;
    border: 4px solid rgba(0, 0, 0, 0.04) !important;
    .img-wrapper {
        border-radius: 24px 24px 0px 0px;
        padding: 0.25rem;
        overflow: hidden;
        .new-launch {
            position: absolute;
            right: 0.25rem;
            width: 11rem;
            top: 2rem;
            @media (max-width: 575px) {
                width: 8rem;
            }
        }
        .tag {
            position: absolute;
            right: 0.25rem;
            height: 3.75rem;
            top: 2rem;
        }
        .upcoming-tag {
            position: absolute;
            border-radius: 0.75rem;
            background: rgba(255, 255, 255, 0.23);
            padding: 0.75rem;
            top: 2rem;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            right: 0;
            left: 0;
            width: 40%;
            color: #fff;
            font-family: Lora;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 800;
            line-height: 150%;
            @media (max-width: 575px) {
                width: 50%;
                font-size: 1rem;
            }
        }
        & > .project-image {
            border-radius: 24px 24px 0px 0px;
            min-height: 19rem;
            height: 100%;

            @media only screen and (max-width: 576px) {
                height: 12.5rem;
                min-height: unset;
            }
        }

        max-height: 19rem;
        @media only screen and (max-width: 900px) {
            max-height: fit-content;
        }
    }
    .card-body {
        padding: 1.125rem !important;
    }
    &:hover {
        transition-timing-function: linear;
        transition-duration: 0.25s;
        border: none !important;
        box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.12);
        & > .img-wrapper {
            margin: 0.25rem 0.25rem 0rem 0.25rem;
            .new-launch {
                right: 0.5rem;
                top: 2.25rem;
            }
            .tag {
                right: 0.5rem;
                top: 2.25rem;
            }
        }
        .upcoming-tag {
            margin-top: 0.25rem;
            width: calc(40% - 4px);
        }
        .card-body {
            margin: 0rem 0.25rem 0.25rem 0.25rem;
        }
    }

    .card-title {
        font-family: $fontBold;
        font-size: 2rem;
        margin-bottom: 1.125rem;
        line-height: 150%;
        @media (max-width: 575px) {
            font-size: 1.5rem;
        }
    }
    .card-text .desc {
        font-family: Work Sans !important;
        font-size: 1rem;
        @media (max-width: 575px) {
            font-size: 1.25rem;
        }
    }
    .upcoming-project-info {
        div.info-grid {
            grid-template-columns: 1fr 1fr !important;
            @media (max-width: 575px) {
                display: flex;
                gap: 1rem;
                flex-direction: column;
            }
        }
    }
    .completed-project-info {
        div.info-grid {
            grid-template-columns: 1fr 1fr !important;
        }
    }
    .project-info {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        // grid-template-columns: 1fr 1fr;
        div.info-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            @media (max-width: 575px) {
                display: flex;
            }
        }
        @media only screen and (max-width: 900px) and (min-width: 600px) {
            // grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        .info-separator {
            width: 2px;
            height: 2rem;
            background: linear-gradient(90deg, #ffd542 0%, #e8a111 100%);
        }

        .project-info-info {
            font-family: $fontBold;
            font-size: 1.125rem;
            @media (max-width: 575px) {
                font-size: 0.875rem;
            }
        }
        .project-info-desc {
            color: #363133;
            font-family: "BentonSansBook";
            font-size: 0.875rem;

            @media (max-width: 575px) {
                font-size: 0.75rem;
            }
        }
        .project-info-2 {
            // .btn-container {
            // max-width: unset;
            // display: flex;
            // justify-content: center;
            // border-radius: 12px !important;
            button {
                border-radius: 0.5rem;
                border: 1px solid $black;
                padding: 0.5rem 1.375rem !important;
                background-color: $black;
                @media (max-width: 575px) {
                    width: 100%;
                }
                .btn-text {
                    font-size: 14px;
                    font-family: "BentonSansMedium";
                    background: linear-gradient(
                        90deg,
                        #ffd542 0%,
                        #e8a111 100%
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    // }
                }
            }
        }
    }
}
