@import "../../../../styles/variables";

.-blog-banner {
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.-imageOther {
    height: 300px;
    width: 100%;
    object-fit: cover;
    margin: 1rem 0;
}

.--label {
    font-size: 1rem;
    line-height: 19px;
    color: #898989;
    margin: 16px 0;
}

.-aside-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 0;
    background: #f7f7f7;
    border-radius: 4px;
    height: 100%;
    text-align: center;
}

.-blog-author {
    //
}

.-authorImage {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.-authorName {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: $black;
    margin: 12px 0;
}

.-department {
    font-size: 18px;
    line-height: 28px;
    color: #898989;
}

.header {
    font-size: 0.875rem;
    color: black;
    line-height: 1.5rem;
}

.margin {
    margin: 1.5rem 0;
}

.imagepadding {
    padding: 0px 2rem;
}

.header2 {
    font-size: 1.5rem;
}

.faq {
    font-size: 1.125rem;
}

.pros-cons {
    display: grid;
    border-radius: 12px;
    border: 1px solid #000;
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }

    .table-col:first-child {
        border-bottom: 1px solid #000;
        @media (min-width: 768px) {
            border-right: 1px solid #000;
            border-bottom: none;
        }
    }

    .pros,
    .cons {
        border-bottom: 1px solid #000;
    }

    ul {
        li {
            list-style-type: disc;
            list-style-position: inside;
        }
    }
}

.table-of-content {
    padding-left: 1ch;
    scroll-margin-block-start: 110px;
}

.table-of-content li {
    list-style-type: none;
    list-style-position: inside;

    &::before {
        content: " ";
        width: 4px;
        height: 4px;
        display: inline-block;
        border-radius: 50%;
        background: #000;
        margin-right: 1rem;
        margin-bottom: 0.1375rem;
    }
}
.table-of-content li li {
    list-style-type: none;
    list-style-position: inside;

    &::before {
        content: " ";
        width: 4px;
        height: 4px;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid;
        background-color: white;
        margin-right: 1rem;
        margin-bottom: 0.1375rem;
    }
}

.table-of-content li > ul {
    margin-left: 1.5rem;
}

.table-of-content li > a {
    text-decoration: none;
    color: inherit;
}
.scroll-top {
    scroll-margin-top: 5rem;
}

.mb-3 {
    margin-bottom: 12px;
}

.font-normal {
    font-weight: 400;
}

.text-lg {
    font-size: 18px;
}

.mivan-table {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.table-wrapper {
    border: 1px solid grey;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 24px;
}

.mivan-table-top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.mivan-table-top:not(:last-child) .table-cell {
    border-bottom: 1px solid grey;
}

.table-cell {
    padding: 16px;
    @media (min-width: 768px) {
        .table-cell {
            padding-left: 24px;
        }
    }

    border-right: 1px solid grey;
    min-width: 33%;
}

.table-cell:last-child {
    border-right: none;
}

.first-row .table-cell:first-child {
    border-top-left-radius: 15px;
}

.first-row .table-cell:last-child {
    border-top-right-radius: 15px;
}

.last-row .table-cell:first-child {
    border-bottom-left-radius: 15px;
}

.last-row .table-cell:last-child {
    border-bottom-right-radius: 15px;
}

.table-header {
    display: flex;
    align-items: center;
    padding-left: 8px;
}

@media (min-width: 576) {
    .table-header {
        padding-left: 24px;
    }
}
@media (min-width: 768px) {
    .table-header {
        padding-left: 36px;
    }
}

.buying-apartment-vs-building-house {
    .blog-list-circle {
        list-style: none !important;
        padding-left: 0;
    }
    .blog-detail-banner {
        .blog-detail-banner-container {
            .banner-wrapper {
                .blog-header {
                    .blog-title {
                        line-height: 2.25rem;
                    }
                }
            }
        }
    }
}

.post-script {
    font-size: 0.75rem;
}

a.external-link {
    color: var(--color-primary-2);
    &:hover {
        text-decoration: underline !important;
    }
}

table.comparison-table {
    border-collapse: separate; /* Important for border-radius to work properly */
    border-spacing: 0;
    border: 1px solid; /* Define a border for the table */
    border-radius: 10px;
    th,
    td {
        padding: 10px;
        border-bottom: 1px solid;
        border-right: 1px solid;
    }
    th:first-child {
        border-top-left-radius: 9px;
    }
    th:last-child {
        border-top-right-radius: 9px;
    }
    tr:last-child > td {
        border-bottom: 0;
    }
    th:last-child,
    td:last-child {
        border-right: 0;
    }

    th {
        background-color: #000;
        color: white;
        text-align: center;
        border-color: black;
    }
}
