@import "../../../styles/variables";
@import "../../../styles/fonts";

.featured-blog-section {
    .featured-blog-heading {
        font-family: $fontBold;
        margin-top: 3rem;
        margin-bottom: 2.5rem;
        font-size: 2rem;
        font-weight: 400;
    }
    .featured-blog-wrapper {
        display: grid;
        grid-template-columns: 4fr 5fr;
        @media (max-width: 575px) {
            display: flex;
            flex-direction: column;
        }
        position: relative;
        gap: 2rem;
        .featured-blog-image {
            display: flex;
            align-items: center;
            width: 100%;
            background-color: #fff !important;
            a {
                width: 100%;
            }
            img {
                height: 17.25rem;
                width: 100%;
                border-radius: 1.5rem !important;
                object-fit: fill;
                // min-height: 20rem;
                @media (max-width: 575px) {
                    height: 13.875rem !important;
                }
            }
        }

        .label {
            position: absolute;
            top: 0;
            left: 0;
            padding: 9px;
            // margin: 8px 0 0 8px;
            color: #000;
            color: #fff;
            font-family: "BentonSansBold";
            text-transform: uppercase;
            font-size: 0.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 19.737px; /* 216.667% */
            border-radius: 6.073px;
            border-top-left-radius: 24px;
            background: rgba(25, 15, 15, 0.12);
        }
        .featured-blog-content {
            display: flex;
            flex-direction: column;
            .featured-blog-text {
                flex: 1 auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-bottom: 0.5rem;
            }
            .featured-blog-label {
                border-radius: 0.5rem;
                background: rgba(25, 15, 15, 0.12);
                padding: 0.675rem;
                font-size: 0.675rem;
                letter-spacing: 0.125rem;
                width: max-content;
            }
            .content-separator {
                width: 1rem;
                height: 2px;
                background: linear-gradient(90deg, #ffd542 0%, #e8a111 100%);
            }
        }
        .featured-blog-title {
            font-family: $fontBold;
            font-size: 1.5rem;
            font-weight: 400;
        }
        .featured-blog-desc {
            color: rgba(0, 0, 0, 0.8) !important;
            font-family: $fontRegular;
            font-size: 0.875rem;
            font-weight: 400;
        }
        .featured-blog-meta {
            font-family: $fontRegular;
            font-size: 0.875rem;
            font-weight: 400;
        }
        .featured-blog-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn {
                border-radius: 0.5rem;
                border: 1px solid #000;
                background: #000;
                padding: 0.25rem 2.5rem;
                .btn-text {
                    font-family: "BentonSansMedium";
                    font-size: 1rem;
                    line-height: 29px;
                    background: linear-gradient(
                        90deg,
                        #ffd542 0%,
                        #e8a111 100%
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            a {
                align-items: center;
                color: #000;
                display: flex;
                font-size: 1rem;
                font-style: normal;
                font-weight: 900;
                justify-content: space-between;
                border-radius: 0.675rem;
                border: 2px solid #000;
                padding: 0.75rem 2.4rem;
                box-shadow: 0px 0px 120px 0px rgba(252, 230, 197, 0.6);
            }
        }
        .featured-blog-navigate {
            display: flex;
            gap: 2rem;
            align-items: center;
            position: relative;
            @media (max-width: 576px) {
                gap: 1rem;
                padding-left: 8px;
            }
            .circle {
                position: relative;
                height: 2.5rem;
                width: 2.5rem;
                cursor: pointer;
                // padding: 0.5rem 0.6rem;
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.05);
            }
            .left-chevron {
                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform-origin: 0% 0%;
                    transform: rotate(180deg) translateX(-50%) translateY(-50%);
                }
            }
            .right-chevron {
                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
            .disabled {
                pointer-events: none;
            }
        }
    }
}

.blog-cate {
    font-size: 12px;
    padding: 16px 34px;
    border: 1px solid $black;
    background: $black;
    border-radius: 8px;
    color: $orange;
    text-transform: uppercase;
    font-weight: $fw600;
    display: inline-block;
    letter-spacing: 3px;

    @media (min-width: $lg) {
        padding: 12px 24px;
    }

    &--gray {
        font-size: 14px;
        line-height: 24px;
        color: #181617;
        background: #f7f7f7;
        border-radius: 4px;
        border: 1px solid #f7f7f7;
        text-transform: capitalize;
        padding: 10px 15px;
        letter-spacing: 2px;

        @media (min-width: $lg) {
            padding: 12px 20px;
        }
    }
}

.FeaturedBlogBanner {
    object-fit: cover;
    width: 100%;
    height: 340px;

    @media (min-width: $lg) {
        height: 600px;
    }
}
