// bgPattern
.bgPattern {
  position: relative;

  &--left {
    @media (min-width: $lg) {
      background: linear-gradient(to left, $white 92%, $lightGray 10%);
    }
  }

  &--right {
    @media (min-width: $lg) {
      background: linear-gradient(to right, $white 92%, $lightGray 10%);
    }
  }

  &--bottom {
    background: linear-gradient(to bottom, $white 40%, $lightGray 20%);

    @media (min-width: $lg) {
      //
    }
  }

  &--black {
    background: $black;
  }

  &--leftSquare,
  &--rightSquare,
  &--leftSquareFull {
    &::after {
      content: "";
      position: absolute;
      width: 35%;
      height: 60%;
      transform: translateY(120px);
      z-index: -1;

      @media (min-width: $lg) {
        background: $lightGray;
      }
    }
  }

  &--rightSquare {
    &::after {
      right: 0;
      top: 0;
    }
  }

  &--leftSquare {
    &::after {
      left: 0;
      top: 0;
    }
  }

  &--leftSquareFull {
    &::after {
      left: 0;
      top: 0;
      width: 70%;
      height: 100%;
      transform: translate(0, 0);
    }
  }

  &--PatternRight,
  &--PatternLeft,
  &--PatternEdge {
    position: relative;
    overflow: hidden !important;

    &::after {
      pointer-events: none;
      content: "";
      position: absolute;
      width: 90%;
      height: 90%;
      z-index: -1;
      bottom: 0;

      @media (max-width: $mmd) {
        height: 60%;
        width: 50%;
      }
    }
  }

  &--PatternLeft {
    &::after {
      width: 100%;
      height: 70%;
      z-index: -1;
      bottom: 20%;
      left: 10%;
      background: url("../images/Urbanest/PatternCenter.svg") no-repeat center
        left;
      background-size: contain;
    }
  }

  &--PatternRight {
    &::after {
      width: 100%;
      height: 70%;
      z-index: -1;
      bottom: 20%;
      right: 10%;
      background: url("../images/Urbanest/PatternCenter.svg") no-repeat center
        right;
      background-size: contain;
    }
  }

  &--PatternEdge {
    &::after {
      z-index: 0;
      transform: translateY(-440px);
      top: 0;
      right: 0;
      bottom: 0;
      background: url("../images/Urbanest/PatternRight.svg") no-repeat center
        right;
    }
  }

  &--HrGrassPattern {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      bottom: 0;
      height: 60px;
      width: 100%;
      right: 0;
      left: 0;
      background: url("../images/Common/HrGrassPattern.svg") repeat-x;
    }
  }

  &--HrGrassPatternTop {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      bottom: 0;
      height: 68px;
      width: 100%;
      right: 0;
      left: 0;
      background: url("../images/Common/HrGrassPattern.svg") repeat-x;
    }
  }

  &--PatternBottom {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 0;
      height: 100vh;
      width: 100%;
      left: 0;
      background: url("../images/Common/ContactUsPattern.svg") no-repeat;
      top: 40%;

      @media (max-width: $mmd) {
        height: 100vh;
      }
    }
  }
}

// imagePatch
.imagePatch {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    // background: yellow;
  }
}

.text-orange {
  color: $orange !important;

  &:hover {
    color: $black !important;

    svg path {
      fill: $black !important;
    }
  }

  svg path {
    fill: $orange !important;
  }
}

.text-black {
  color: $black !important;
}

.MdWhite {
  @media (min-width: $md) {
    color: $white;
  }
}

// BUTTON
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.form-control.is-valid,
.was-validated .form-control:valid {
  box-shadow: none;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: $white;
  background-color: $black;
  border-color: $black;
  box-shadow: none;
  outline: none;
}

.btn {
  font-weight: $fw600;
  font-size: 16px;
  line-height: 26px;
  padding: 16px 48px;
  border: 1px solid $black;
  transition: $transition6;

  @media (max-width: $mmd) {
    padding: 10px 16px;
  }

  &--submit {
    background: $black;
    border-radius: 0.75rem;
    color: $yellow;

    &:hover,
    &:focus {
      background: #000000bb;
      // border: 1px solid $orange;
      transition: $transition6;
      color: $yellow !important;
    }
  }

  &--small {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    background: $black;
    color: $white;
    border-radius: 8px;
    display: inline-block;
    border: 1px solid $black;
    border: 1px solid $orange;
    transition: $transition;
    padding: 10px 16px;
    @extend .text-orange;

    min-width: 200px;

    @media (min-width: $lg) {
      padding: 16px 40px;
      font-size: 16px;
      line-height: 26px;
    }

    &:hover {
      color: $black;
      background: $white;
      border: 1px solid $black;
      transition: $transition;
      border-radius: 0;

      svg path {
        fill: $black;
      }
    }

    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 100%;
      background: #fff;
      transition: all cubic-bezier(0.37, 0.68, 0.27, 0.71) 0.4s;
      z-index: -1;
    }

    &:hover::after {
      width: 100%;
    }
  }
}

// C
.ZIndexPosRel {
  z-index: 1;
  position: relative;
}

.max-width-380 {
  @media (min-width: $mmd) {
    max-width: 380px;
  }
}

// MASTER PALA SPACES - HEADING DOWNLOAD OPTION

.-only-sm {
  @media (max-width: $md) {
    width: 123px;
    text-align: left;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.UrbanestLogo {
  width: 200px;

  @media (max-width: $md) {
    width: 140px;
    object-fit: contain;
    // margin-bottom: 3rem;
  }
}

.noScroll::-webkit-scrollbar {
  display: none;
}

.section-contact-main .desc,
.section-blogs .desc,
.section-features .desc,
.section-showcase .desc,
.section-testimonials .desc,
.desc {
  font-family: $fontBarlow !important;
}

// .section-blogs .name,
.section-showcase .name,
.section-features .name,
.section-testimonials .tagline,
.ms-thank-you-block .heading,
.form-container .heading {
  font-family: $fontLora !important;
}

.section-header .item-link,
.section-footer .list-item,
.app-btn--hollow--white .app-btn-text,
.desktop-menu .menu-item,
.desktop-menu .menu-item.contact-item a,
.menu-label,
.desktop-menu .menu-item.poject-item {
  font-family: $fontBai !important;
}

.pos-rel {
  position: relative;
}

.separator-only-sm {
  position: absolute;
  left: 0;
  height: 25px;
  width: 2.5px;
  background: #ffd542;
  top: 0;
}

//
.-green,
.-yellow,
.-brown,
.-red {
  fill: black;
}

.just-center {
  display: flex;
  align-items: center;
  justify-content: center;

  // @media(min-width:$sm) {
  // }
  a {
    @media (min-width: $lg) {
      font-size: 18px;
      line-height: 28px;
      font-weight: $fw900;
    }
  }
}

a {
  transition: $transition6;

  &:hover,
  &:focus {
    color: $orange !important;
    transition: $transition6;
  }
}

.modal-video-body {
  @media (min-width: $lg) {
    max-width: 1050px !important;
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}

.cu-none {
  cursor: none !important;
  // background: red !important;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: initial;
}

a.pointNone {
  pointer-events: none;
  cursor: not-allowed;
}

// DATE PICKER
.react-datepicker {
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  box-shadow: 0 5px 20px #cdc4c4;
  font-family: $fontBarlow !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  border-radius: 50% !important;
  text-align: center !important;
  // background: #ff9800 !important;
  background: $orange !important;
}

.react-datepicker__header {
  background-color: transparent;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  display: none;
}

.react-datepicker__navigation-icon--next::before {
  content: "";
  background: transparent url("../images/Common/Right.svg") no-repeat center;
  border: none;
  top: 12px;
  transform: rotate(0deg);
}

.react-datepicker__navigation-icon--previous::before {
  content: "";
  background: transparent url("../images/Common/Left.svg") no-repeat center;
  border: none;
  top: 12px;
  transform: rotate(0deg);
}

.react-datepicker__day-names {
  margin-bottom: -8px;
  padding: 7px 0;
}

.react-datepicker__day--weekend,
.react-datepicker__day-name {
  font-weight: 900;
}

.react-datepicker__month {
  padding: 5px;
}

// DATE PICKER
.SoulTreeMasterPlanNew {
  object-fit: contain;
  width: 100%;
  height: 350px;

  @media (min-width: $md) {
    // width: 950px;
    height: 650px;
  }
}

.GSliderHoverEle {
  position: relative;
}

.DotRingParent {
  position: absolute;
}

.msSoulTreeBannerLogo {
  &--sm {
    @media (max-width: 1199px) {
      display: block;
    }

    @media (min-width: 1200px) {
      display: none;
    }
  }

  &--md {
    @media (max-width: 1199px) {
      display: none;
    }

    @media (min-width: 1200px) {
      position: absolute;
      right: 10%;
      top: 50%;
      transform: translate(0, -50%) scale(1.2);
      display: block;
    }

    @media (min-width: 1366px) {
      // transform: translate(-207px, 232px) scale(1.6);
    }

    @media (min-width: 1536px) {
      // transform: translate(-207px, 232px) scale(1.6);
    }

    @media (min-width: 1800px) {
      // transform: translate(-207px, 232px) scale(1.6);
    }
  }
}

.ril__closeButton {
  border: 2px solid white !important;
  top: 20px !important;
  position: absolute !important;
  right: 10px !important;
  border-radius: 50% !important;
  height: 42px !important;
}

.-only-mobile {
  @media (max-width: 1365px) {
    display: block !important;
  }

  @media (min-width: 1366px) {
    display: none !important;
  }
}

.-only-desktop {
  @media (max-width: 1365px) {
    display: none !important;
  }

  @media (min-width: 1366px) {
    display: block !important;
  }
}

.-only-both {
  @media (max-width: 1365px) {
    display: block !important;
  }

  @media (min-width: 1366px) {
    display: block !important;
  }

  .shareLinkBlockList {
    flex-direction: row !important;
    display: flex !important;
  }

  .-share-link {
    margin: 12px 20px 12px 0;
  }
}

.simpleShadow {
  background: white;
  border-radius: 5px;
  // box-shadow   : 3px 0px 12px #b1afaf;
}

.-descBlack {
  font-size: 16px;
  line-height: 26px;
  color: $black;
  font-family: $fontBarlow;

  @media (min-width: $md) {
    font-size: 18px;
    line-height: 28px;
  }
}

.AnchorGradientLink {
  color: $orange;
  background: $transparent;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 24px;
  transition: $transition;
  font-weight: $fw600;

  &:focus,
  &:hover {
    transition: $transition;
    text-decoration: underline !important;
  }
}

.text-white {
  color: $white;
}

.-blog-bannerPlayIcon {
  width: 75px;
  height: 75px;

  @media (max-width: 992px) {
    width: 50px;
    height: 50px;
  }
}

.play-button2 span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  // background-color: $orange;
  background-color: #ff9100;
  border-radius: 50%;
  position: relative;
  z-index: 2;

  @media (max-width: 992px) {
    width: 50px;
    height: 50px;
  }
}

.-bannerPlayIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 75px;
  height: 75px;
  transform: translate(-50%, -50%);

  @media (max-width: 992px) {
    width: 50px;
    height: 50px;
  }
}

.play-button2 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 75px;
  height: 75px;
  transform: translate(-50%, -50%);

  @media (max-width: 992px) {
    top: 50%;
    width: 50px;
    height: 50px;
    left: 20%;
  }

  &--sm {
    left: auto;
    right: 0;
  }
}

.play-button2::before {
  animation: play-button2 2s infinite 0s;
}

.play-button2::before,
.play-button2::after,
.play-button2 span::before,
.play-button2 span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: inherit;
  border-radius: 50%;
  opacity: 0.2;
  z-index: -1;
}

.play-button2 span::before {
  animation: play-button2 2s infinite 0.25s;
}

.play-button2 span::after {
  animation: play-button2 2s infinite 0.5s;
}

.play-button2::after {
  animation: play-button2 2s infinite 0.15s;
}

@keyframes play-button2 {
  0% {
    opacity: 0.8;
    transform: scale(1);
  }

  75% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.left0 {
  left: 0 !important;
}

.TataBannerImage {
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (min-width: $lg) {
    height: 100vh;
  }
}
