@import "../../../styles/variables";

input[type="radio"] {
    visibility: hidden;
}

input[type="radio"]:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: #fff;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid #000;
}

input[type="radio"]:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -1px;
    left: -1px;
    position: relative;
    // background-color: #000;
    background: radial-gradient(#000 50%, #fff 50%);
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid #000;
}

// .ContactUsForm {}
.section.connect-with-us {
    margin-top: 3.75rem;
    padding-bottom: 10.125rem !important;

    @media (max-width: 575px) {
        padding-bottom: 8.125rem !important;
    }

    .background {
        background: rgba(0, 0, 0, 0.02);
        .content > .row {
            padding: 2.5rem 0;
        }
    }
    .-title {
        font-family: "BentonSansBold" !important;
        font-size: 2rem;
        margin-bottom: 1.25rem;
        font-weight: 400;
        line-height: 150% !important;
        @media (max-width: 575px) {
            // text-align: center;
            font-size: 1.5rem;
        }
    }
    .-desc {
        font-family: "BentonSansBook";
        font-size: 0.875rem;
        // @media (max-width: 576px) {
        //     text-align: center;
        // }
    }
    .ContactUsForm {
        input,
        .input-group-text {
            font-family: "BentonSansBook";
            font-weight: 400;
        }
        @media (max-width: 575px) {
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
                width: max-content;
                padding: 1rem 5rem;
            }
        }
        .form-group {
            margin-bottom: 1.75rem;
        }
        .form-label {
            font-family: "BentonSansBook" !important;
            font-size: 0.875rem;
            color: #000;
            opacity: 0.6;
            text-transform: none;
            margin-bottom: 0 !important;
            @media (max-width: 575px) {
                font-size: 1rem;
            }
        }
        button {
            border-radius: 0.5rem;
            border: 1px solid #000;
            background: #000;
            padding: 0.875rem 3.375rem;
            .btn-text {
                font-family: "BentonSansMedium";
                font-size: 1rem;
                line-height: 29px;
                background: linear-gradient(90deg, #ffd542 0%, #e8a111 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}
