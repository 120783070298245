@import "../../../styles/variables";
@import "../../../styles/fonts";

.all-resources-section {
    // background-color: red;
    // @media (max-width: 576px) {
    padding-bottom: 1rem !important;
    // }
}

.BlogsList {
    .all-resources-heading {
        font-family: $fontBold;
        font-weight: 400;
        font-size: 2rem;
    }
    .card-deck {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.5rem;
        @media (max-width: 990px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 530px) {
            grid-template-columns: 1fr;
        }
    }
    .card-deck .card {
        flex: 1 0 100%;
        // margin-bottom: 40px;
        background: $white;
        // box-shadow: $boxShadow2;

        @media (min-width: $ssm) {
            flex: 1 0 30%;
        }

        @media (min-width: $smd) {
            flex: 1 0 40%;
        }
    }

    .card-footer {
        background-color: transparent;
        // border: none;
    }
}

.load-more {
    margin-top: 3rem;
    justify-content: center;

    @media (max-width: $sm) {
        margin-top: 3rem;
    }
}

.--image {
    width: 100%;
    height: 200px;

    @media (min-width: $lg) {
        height: 300px;
    }
}
