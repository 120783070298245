@import "../../styles/variables";
@import "../../styles/fonts";

.section-StoryOfGrowth {
    padding-top: 6.25rem !important;
    padding-bottom: 0rem !important;

    @media (max-width: 575px) {
        padding-top: 3rem !important;
    }

    .StoryOfGrowth {
        @media (max-width: 575px) {
            padding: 0;
            &__heading {
                font-size: 1.5rem !important;
                font-family: "BentonSansBold";
            }
            &__subheading {
                font-size: 1.125rem !important;
                font-family: "BentonSansBold";
            }
        }

        .content {
            @media (min-width: 576px) {
                text-align: center;
            }
        }

        .content.section-1 {
            & > div {
                margin-bottom: 13.5rem;
                @media (max-width: 576px) {
                    margin-bottom: 5.25rem;
                }
            }
        }

        .content.section-2 {
            @media (max-width: 576px) {
                padding: 0;
            }
            @media (min-width: 576px) {
                text-align: center;
            }
        }
        &__heading {
            font-family: "BentonSansBold";

            font-size: 2rem;
        }
        &__subheading {
            // font-family: "BentonSansBold";
            font-size: 1.5rem;
        }
        &__desc {
            font-family: "BentonSansBook";
            font-size: 0.875rem;
        }
        &__know-more-container {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 12.5rem 0rem 3rem 0rem;
            @media (max-width: 575px) {
                padding: 10rem 1rem 4rem 1rem;
            }
            color: white;
            background-color: black;
            .know-more-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                // align-items: center;
                @media (max-width: 575px) {
                    padding: 4rem 0 0 0;
                    align-items: initial;
                }
                h3,
                h5 {
                    line-height: 150% !important;
                }
                h3 {
                    font-weight: 600;
                    font-size: 1.5rem;
                    font-family: "BentonSansBook";
                }
                h5 {
                    font-family: "BentonSansBook";
                    font-size: 0.875rem;
                }
                @media (max-width: 575px) {
                    h3 {
                        font-size: 1.125rem !important;
                        line-height: 2.25rem !important;
                        font-family: $fontBold;
                    }
                    h5 {
                        font-size: 0.875rem !important;
                        font-family: $fontRegular;
                        line-height: 150% !important;
                    }
                }
            }
        }

        &__stats-container {
            position: absolute;

            top: -30%;
            z-index: 1;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 2rem;
            padding: 0 1rem;
            @media (max-width: 576px) {
                display: none;
            }
        }
        .stat-card {
            background-color: white;
            display: flex;
            text-align: center;
            flex-direction: column;
            align-items: center;
            padding: 3rem 3.75rem;
            margin: auto;
            width: 100%;

            color: black;
            box-shadow: 8px 0px 32px 0px rgba(0, 0, 0, 0.12);
            font-family: Poppins;

            @media (max-width: 576px) {
                width: max-content;
            }

            .stat-stat {
                font-size: 4.5rem;
                font-weight: 700;
                line-height: 80% !important;
            }
            .stat-unit {
                font-size: 1rem;
                margin-top: 1rem;
                font-weight: 300;
            }
            .stat-info {
                font-size: 1.5rem;
                margin-top: 0.75rem;
                font-weight: 600;
            }
        }
        &__stats-container-mobile {
            position: absolute;

            // @media (max-width: 576px) {
            //     top: -15%;
            // }

            top: -25%;
            z-index: 1;
            .carousel {
                .control-dots {
                    .dot.selected {
                        width: 2rem;
                        border-radius: 25%;
                        background-color: $white;
                    }
                }
                .slider-wrapper {
                    box-shadow: none;
                    padding-top: 2rem;
                }
            }

            // .stat-card {

            //     border-radius: 0.75rem;
            //     padding: 1.5rem 4rem;

            //     .stat-stat {
            //         font-size: 5rem;
            //     }
            // }
        }
        // @media screen and (max-width: 500px) {
        //   &__know-more-container {
        //     padding-top: 4rem;
        //   }
        //   &__stats-container {
        //     z-index: 1;
        //     position: initial;
        //     display: flex;
        //     flex-wrap: wrap;
        //     flex-direction: row;
        //     padding-bottom: 4rem;
        //     width: min-content;

        //     .stat-card {
        //       width: max-content;
        //       flex: 1 1 100%;
        //     }
        //   }
        // }
    }
}
