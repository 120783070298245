@import "../../../styles/variables";

.bg-lightGray {
  background: $lightGray;
}

.GalleryTabs {
  // padding-bottom: 4rem;
  // RECORD AUDIO BASED CHANGE
  padding-bottom: 2.5rem;

  @media (min-width: $lg) {
    padding-bottom: 3.5rem;
  }

  .nav-tabs {
    border-bottom: 0;
    margin-bottom: 24px;
  }

  .nav-item {
    &.nav-link {
      position: relative;
      border: transparent;
      background: transparent;
      pointer-events: none;
      padding: 0.5rem 2rem 1rem 0.5rem;

      .-title {
        color: $black03 !important;
        padding: 0 !important;
      }

      &.active .-title {
        color: $black !important;
        padding: 0 !important;
      }

      &::after,
      &.active::after {
        left: 10px;
        position: absolute;
        bottom: 8px;
        content: "";
        border-radius: 50px;
        display: inline-block;
        width: 40px;
        height: 4px;
        transition: $transition6;
      }

      &::after {
        background: $transparent;

        @media (min-width: $lg) {
          height: 6px;
          bottom: 12px;
          left: 14px;
        }
      }

      &.active {
        &::after {
          background: $black;

          @media (min-width: $lg) {
            left: 14px;
          }
        }
      }
    }
  }
}

.GalleryTabs-Container {
  .tab-content {
    width: 100%;
  }

  &--secondNoTab {
    .GalleryTabs.GalleryTabs--black {
      [data-rb-event-key="Walkthrough"] {
        // display: none !important;
      }
    }
  }
}

.GalleryImage {
  // height: 100%;
  object-fit: cover;
  // height    : 90vh;
  // RECORD AUDIO BASED CHANGE
  height: 310px;

  @media (min-width: $lg) {
    // height: 70vh;
    // RECORD AUDIO BASED CHANGE
    height: 90vh;
  }

  @media (min-width: $extrXs) {
    height: 85vh;
  }

  @media (min-width: $extrLg) {
    height: 100%;
  }
}

.GalleryImageSlide {
  height: 60vh;
  object-fit: cover;

  @media (min-width: $lg) {
    height: 500px;
  }

  @media (min-width: $extrXs) {
    height: 85vh;
  }

  @media (min-width: $extrLg) {
    height: 100%;
  }
}

////////////////////////////////// LightHouseImage
.LightHouseImage {
  width: 100%;
  height: 50vh;
  object-fit: cover;
  cursor: pointer;
  @media (min-width: $md) {
    height: 80vh;
  }
}
img.ril-image-current.ril__image {
  // width: 100%;
  // height: 84vh;
  // margin: 0 auto;
  // object-fit: contain;
}
.ril-zoom-in,
.ril-zoom-out {
  display: none;
}

.ril__toolbar {
  background-color: transparent !important;
}
.ril__outer {
  background-color: rgba(0, 0, 0, 0.93);
}
.ril__image {
  cursor: none !important;
  pointer-events: none !important;
}

.ril__navButtonPrev,
.ril__navButtonNext {
  // border-radius: 50%;
  width: 20px;
  height: 34px;
  padding: 30px 30px;
}

////////////////////////////////// LightHouseImage
