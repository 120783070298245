@import "../../../styles/variables";

// BANNER IMAGE

.EngraceLogo {
    width: 200px;
}
.-banner-image-engrace {
    width: 100%;

    @media (min-width: $md) {
        padding-bottom: 11rem;
    }
}
