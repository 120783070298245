@import "../../styles/variables";

.logo {
    display: block;
    // background: url("../../images/ms-text-logo.svg") no-repeat 0 0
    //   var(--color-black);
    background: url("../../images/logo-sq.svg") no-repeat 0 0 var(--color-black);
    // width              : 95px;
    // height             : 95px;

    width: 3.75rem;
    height: 3.75rem;
    // width: 150px;
    // height: 140px;
    margin: 0;
    // background-size    : 70px 70px;
    background-size: 90% 90%;
    cursor: pointer;
    // position: absolute;
    top: 0;
    background-position: center;

    // @media (max-width: 992px) {

    //   // width: 4.5rem;
    //   // height: 4rem;
    //   // margin: 0;
    //   cursor: pointer;
    //   // position: absolute;
    //   top: 0;
    //   background-position: center;
    //   background-size: contain;
    // }
}

.header-logo {
    margin: 0;
    background-size: 90% 90%;
    top: 0;
    background-position: center;
}

.section-header {
    height: 4.5rem;
    padding: 1rem 0;
    animation-name: expanding;
    animation-duration: 700ms;
    z-index: 990;
    position: fixed;
    top: 0;
    width: 100vw !important;
    @media (max-width: 576px) {
        .content {
            padding: 0 1.5rem;
        }
    }
    .navigation {
        .main-links {
            display: grid;
            justify-items: center;
            grid-template-columns: 1fr 1fr 0.95fr;
            gap: 1rem;
        }
    }

    .mobile-tel {
        position: absolute;
        right: 100px;
        @media screen and (min-width: 766px) {
            display: none !important;
        }
    }

    button.item-link {
        border: none;
        background: none !important;
        :hover {
            color: $yellow;
        }
    }
    button.item-link.project-menu-open {
        color: $yellow !important;
    }

    .item-link-menu {
        position: absolute;
        top: -10rem;
        opacity: 0;
        transition: all ease 300ms;
        min-width: 30em;
    }

    // position: relative;

    .link-wrapper {
        .item-link-menu {
            @media (min-width: 475px) {
                border-radius: 1.125rem;
            }
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            top: 4.5rem;
            margin-left: -7rem;
            z-index: -1;
        }
    }
    .link-wrapper.hide {
        .item-link-menu {
            visibility: hidden;
            opacity: 0;
            transform: translateY(-7rem);
        }
    }

    .link-wrapper.show {
        .item-link-menu {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }
    }

    .icon {
        path {
            stroke: var(--color-white);
        }
    }
    @media (min-width: 576px) {
        .extra-links {
            transition: max-width ease 500ms;
            max-width: 500px;
            overflow: hidden;

            &.hide {
                max-width: 0;
            }
        }
    }
    .item-link.menu-item {
        &:hover {
            font-family: "BentonSansBook" !important;
        }
        .project-tag {
            font-family: "BentonSansBook";
            font-size: 0.5rem;
            border-radius: 6px;
            padding: 0.25rem;
            background-color: #f6c02e29;
            color: #877226;
            margin-left: 0.5rem;
        }
    }

    .item-link {
        font-family: "BentonSansBook" !important;
        font-size: 0.875rem;
        text-decoration: none;
        transition: all 150ms;
        flex: none;
        display: block;
        transition: font-weight 0.3s ease;
        &:hover {
            color: $yellow !important;
            .icon-arrow path {
                stroke: $yellow;
            }
        }
        .icon-arrow path.yellow-stroke {
            stroke: $yellow;
        }
    }

    .separator-v {
        width: 3px;
        margin: 0 0.75rem;
        height: 21px;
        background: linear-gradient(
            180deg,
            var(--color-primary-1) 0%,
            var(--color-primary-2) 100%
        );
    }

    .separator-v-small {
        width: 1.704px;
        margin: 0 0.75rem;
        height: 20px;
        background: rgba(255, 255, 255, 0.3);
    }

    .v-line {
        width: 100%;
        height: 1px;
    }

    .app-btn--hollow--primary {
        margin-left: 1rem;
        padding: 0.5rem 1.25rem !important;
        border-radius: 0.5rem;
        font-family: "BentonSansBold";
        border: 2px solid var(--color-primary-2);
        height: unset;
        .btn-text {
            background: linear-gradient(
                90deg,
                var(--color-primary-1) 0%,
                var(--color-primary-2) 100%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 1rem;
            font-family: Work Sans;
            font-weight: 600;
        }

        & > span {
            line-height: 1.5rem;
            font-size: 0.875rem;
        }
    }

    .icon-toggle-nav {
        cursor: pointer;
        width: 54px;
        height: 54px;
        display: inline-block;
        top: 0;
        position: relative;
        transition: all ease-in-out 700ms;
        margin: 0 0.25rem;
        // background-image: url(../../images/hamburger-menu.svg);
        background-image: url(../../images/hamburger-menu-dots.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        &.icon-close {
            width: 24px;
            height: 24px;
            background-image: url(../../images/hamburger-menu-close.svg);
        }
    }
}

@keyframes expanding {
    from {
        top: -5rem;
    }

    to {
        top: 0;
    }
}

@media (max-width: 576px) and (min-width: 474px) {
    .navbar-menu-responsive {
        right: 0;
    }
}
@media (max-width: 474px) {
    .navbar-menu-responsive {
        position: fixed;
        left: 0;
        top: 5em !important;
        min-width: 100% !important;
        flex-direction: column;
    }
}
