@import "../../../styles/variables";

// @media (max-width: $ssm) {}
// @media (min-width: $mmd) {}
// color: $white;
// .Campus {}
.Green__image {
  height: 500px;
  object-fit: cover;
}
