@import "../../styles/variables";
.section-hero {
    height: 100vh;
    padding: 3rem 0 4.5rem 0 !important;

    background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
        ),
        // url("../../images/soulace-homepage-banner.webp"),
        lightgray 0px -98.431px / 100% 113.435% no-repeat;
    background-size: cover;
    background-position: center;

    .content-fluid {
        height: 70%;
        width: 100%;
        position: absolute;
        bottom: 0;

        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 82.42%);
    }
    .content.hero-content {
        color: $white;

        & > div {
            @media (max-width: 576px) {
                bottom: 8rem !important;
            }
        }

        .heading {
            font-family: "BentonSansBold";
            font-size: 3.5rem;
            @media (max-width: 576px) {
                font-size: 2rem;
            }
        }
        .h-separator {
            margin: 1.125rem 0;
        }
        .contact-details {
            gap: 1rem;
            font-size: 1.125rem;
            @media (max-width: 576px) {
                font-size: 0.875rem;
                gap: 0.5rem;
            }
            @media (max-width: 340px) {
                flex-direction: column;
                align-items: flex-start !important;
                .v-separator {
                    display: none;
                }
            }
            font-family: "BentonSansBook";
            .v-separator {
                width: 17px;
                height: 2px;
                transform: rotate(90deg);
                border-radius: 4px;
                background: rgba(255, 255, 255, 0.32);
            }
        }
        button {
            border: 2px solid var(--color-primary-2);
            border-radius: 0.5rem;
            height: unset !important;
            padding: 0.875rem 2.5rem !important;
            margin-top: 2.5rem;
            .btn-text {
                background: linear-gradient(
                    90deg,
                    var(--color-primary-1) 0%,
                    var(--color-primary-2) 100%
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 1rem;
                font-family: Work Sans;
                font-weight: 600;
            }
        }
    }

    .list-item {
        font-size: 16px;
        line-height: 26px;
        font-weight: 600;
    }

    .separator-v {
        width: 3px;
        background: linear-gradient(
            to right,
            var(--color-primary-1),
            var(--color-primary-2)
        );
    }

    .separator-h {
        height: 3px;
        width: 10px;
        background: linear-gradient(
            to right,
            var(--color-primary-1),
            var(--color-primary-2)
        );
    }

    .feature-label {
        font-size: 2rem;
        font-weight: 700;
        font-family: "Lora", serif;
    }

    .feature {
        font-size: 1.25rem;
        opacity: 0.8;
    }
}

@media screen and (max-device-width: 767px) {
    .section-hero {
        height: 100vh;

        .list-item {
            font-size: 14px;
            line-height: 24px;
        }

        .app-btn--solid--primary {
            width: 100% !important;
        }
    }
}
