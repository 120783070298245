.projects-tabs {
    .tab-content {
        padding-top: 2.5rem;
    }
    .projects-grid {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: 1fr 1fr;
        @media only screen and (max-width: 900px) {
            grid-template-columns: 1fr;
        }
    }
    .nav-tabs {
        justify-content: center;
        gap: 2rem;
        @media (max-width: 575px) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            overflow-x: scroll;
            justify-content: unset;
            &::-webkit-scrollbar {
                display: none;
            }
            .nav-item {
                width: max-content;
            }
        }
        .nav-item {
            border: none !important;
            border-bottom: 4px solid #f2f2f2 !important;
            transition: none !important;
            padding: 0 0 0.5rem 0;
            color: black !important;
            font-family: "BentonSansBook";
            font-size: 1.125rem;
            min-width: 11rem;
            text-align: center;

            &:hover {
                color: black;
                border: none !important;
                border-bottom: 4px solid #f2f2f2 !important;
                padding: 0 0 0.5rem 0;
            }
        }
        .active {
            font-family: "BentonSansBold";
            border: none !important;
            border-bottom: 4px solid var(--color-primary-1) !important;
            &:hover {
                border-bottom: 4px solid var(--color-primary-1) !important;
            }
            color: black !important;
        }
    }
}
