@import "../../styles/variables";
.mobile-menu {
    position: fixed;
    transition: left 400ms;
    transition-timing-function: ease-in-out;
    height: 100vh;
    right: 0;
    overflow-y: scroll;
    top: 0;
    z-index: 998;
    &.show {
        left: 0;
    }
    &.hide {
        left: 100vw;
    }
    .header {
        position: fixed;
        z-index: 2;
        background-color: $white;
    }
    .mobile-logo {
        margin: 0;
        background-size: 100% 100%;
        cursor: pointer;
    }
    @media (max-width: 300px) {
        .mobile-logo,
        .icon-close-nav {
            width: 80%;
            height: 80%;
        }
    }

    .v-menu {
        gap: 2rem;
        margin-top: 5.5rem;
        .divider {
            height: 1px;
            background-color: rgba(0, 0, 0, 0.12);
            width: 100%;
        }
        .accordion {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            .card {
                border: none;
                .card-header {
                    padding: 0;
                    background-color: transparent;
                    border: none;

                    button {
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        padding: 0;
                    }
                }
                .card-body {
                    padding: 0.75rem;
                    ul {
                        list-style: disc inside;
                        display: flex;
                        flex-direction: column;
                        gap: 0.75rem;
                    }
                }
            }
        }
    }
    .menu-label {
        font-family: "BentonSansBold" !important;
        font-size: 1.125rem;
        position: relative;
        .fc-dark-3 {
            color: rgba(0, 0, 0, 0.5) !important;
        }
    }
    .menu-item {
        font-size: 1rem;
        font-family: "BentonSansBook";
        transition: all 150ms;
        text-decoration: none;
    }
    .h-line {
        width: 100%;
        display: block;
        height: 1px;
        position: absolute;
        top: 6px;
        z-index: -1;
    }
    .icon-close-nav {
        cursor: pointer;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url(../../images/hamburger-menu-close.svg);
    }
    .btn-wrapper {
        padding-bottom: 5rem;
        button {
            border-radius: 0.5rem;
            padding: 0.75rem 1.5rem;
            height: unset;
            gap: 10px;
            // justify-content: center;
        }
        .btn-text {
            background: linear-gradient(90deg, #ffd542 0%, #e8a111 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: "BentonSansMedium";
            line-height: 150%;
        }
    }
}
