@import "../../../styles/variables";

// @media (max-width: $ssm) {}
// @media (min-width: $mmd) {}
// color: $white;
// .Campus {}
.ExcellentConnectivity__image,
.Campus__image {
  @media (max-width: 992px) {
    height: 411px;
    object-fit: cover;
    // background: red;
  }
}

.ExcellentConnectivity__image {
  @media (max-width: 992px) {
    object-fit: contain;
  }
}
