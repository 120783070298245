html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    color: var(--color-black) !important;
    // font-family: "Bai Jamjuree", sans-serif !important;
    // overflow-y: hidden;
    font-family: $fontBarlow;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
