@import "../../styles/variables";
.ProjectResources-section {
    .ProjectResource__title {
        margin-bottom: 0.75rem;
    }
    .ProjectResource__desc {
        font-size: 1rem !important;
        line-height: 150% !important;
    }
    .resources-cards-container {
        justify-content: center;
        @media (max-width: 768px) {
            flex-direction: column !important;
            gap: 2rem;
        }
    }
    .resource-wrapper {
        @media (min-width: 768px) {
            max-width: 33%;
        }
    }
    .resource {
        padding: 2rem;
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.02);
        align-items: center;

        .resource-name {
            font-family: $fontBarlow;
            font-size: 1rem;
            font-weight: 700;
            line-height: 162.5%;
            margin-bottom: 0.675rem;
        }
        .resource-icon {
            margin-bottom: 2rem;
        }
        .resource-download-btn {
            border-radius: 0.5rem;
            border: 1px solid #000;
            background: #000;
            padding-right: 22px;
            padding-left: 22px;
            height: 2.5rem;
            .btn-text {
                margin-right: 10px;
                font-family: "BentonSansMedium";
                font-size: 0.875rem;
                background: linear-gradient(90deg, #ffd542 0%, #e8a111 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}
