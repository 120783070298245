@import "../../../styles/variables";

.new-launch-project-modal {
    .modal-dialog {
        max-width: 56.25rem;
        @media (max-width: 575px) {
            max-width: 90%;
        }
    }
    .modal-content {
        border-radius: 1.5rem;
        padding: 0;

        .modal-body {
            padding: 0;
            display: grid;
            grid-template-columns: 1.1fr 1fr;
            @media (max-width: 575px) {
                // grid-template-columns: none;
                // grid-template-rows: ;
                display: flex;
                flex-direction: column;
            }
            .modal-banner {
                border-top-left-radius: 1.5rem;
                border-bottom-left-radius: 1.5rem;
                overflow: hidden;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                @media (max-width: 575px) {
                    height: 10.5rem;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 1.5rem;
                }

                background: linear-gradient(90deg, #ffd542 0%, #e8a111 100%);
                img.banner-image {
                    width: 100%;
                    border-top-left-radius: 1.5rem;

                    position: absolute;
                    top: 0;
                    left: 0;
                    @media (max-width: 575px) {
                        width: 50%;
                    }
                }
                .white-arrow {
                    position: absolute;
                    right: -3.25rem;
                    margin-bottom: 0.75rem;
                }
                .banner-text {
                    position: relative;
                    margin-right: 1.5rem;
                    margin-bottom: 4.5rem;
                    @media (max-width: 575px) {
                        margin-bottom: 0.5rem;
                    }

                    .banner-text-heading {
                        font-size: 3rem;
                        font-family: "BentonSansBold";
                        text-align: end;
                        line-height: 110%;
                        @media (max-width: 575px) {
                            font-size: 2rem;
                            line-height: 110%;
                            padding-top: 1rem;
                            margin-bottom: 0.75rem !important;
                        }
                    }
                    // .banner-text-desc {
                    @media (min-width: 576px) {
                    }
                    .desc {
                        font-size: 1rem;
                        font-family: "BentonSansMedium" !important;
                        width: 75%;
                        @media (max-width: 575px) {
                            font-size: 0.75rem;
                        }
                    }
                    .text-marker {
                        height: 2px;
                        border-radius: 2px;
                        width: 1.5rem;
                        background-color: $white;
                    }
                }
                // }
            }
            .modal-form {
                padding: 3rem 1rem 1.5rem 1.5rem;
                @media (max-width: 576px) {
                    padding: 1rem;
                }
                .modal-heading {
                    font-size: 1.5rem;
                    font-family: "BentonSansBold";
                    padding-right: 1.5rem;
                    line-height: 150%;
                    @media (max-width: 576px) {
                        padding-right: 0;
                        font-size: 1.125rem;
                        margin-bottom: 0.5rem;
                    }
                }
                .desc {
                    font-size: 0.875rem;
                    font-family: "BentonSansBook" !important;
                    padding-right: 2rem;
                    line-height: 27px;
                    margin-bottom: 1.5rem;
                    @media (max-width: 576px) {
                        padding-right: 0;
                        line-height: 150%;
                    }
                }

                .ContactUsForm {
                    .form-group {
                        padding-left: 0 !important;
                        padding-right: 1.5rem !important;
                        margin-bottom: 1.5rem;
                        .input-group-text {
                            font-family: "BentonSansBook";
                            font-size: 0.75rem;
                        }
                        .form-label {
                            color: rgba(0, 0, 0, 0.4);
                            font-family: "BentonSansBook";
                            text-transform: none;
                            font-size: 0.75rem;
                        }
                        .form-check-label {
                            font-family: "BentonSansMedium";
                            font-size: 0.875rem;
                            color: $black;
                            margin-left: 0.25rem;
                        }

                        .form-check-input:checked {
                            color: $black;
                            background-color: $black;
                        }
                        .form-control {
                            font-family: "BentonSansBook";
                            font-size: 0.75rem;
                        }
                        input[type="radio"] {
                            visibility: hidden;
                        }
                        input[type="radio"]:after {
                            width: 15px;
                            height: 15px;
                            border-radius: 15px;
                            top: -2px;
                            left: -1px;
                            position: relative;
                            background-color: #fff;
                            content: "";
                            display: inline-block;
                            visibility: visible;
                            border: 1px solid #000;
                        }

                        input[type="radio"]:checked:after {
                            width: 15px;
                            height: 15px;
                            border-radius: 15px;
                            top: -2px;
                            left: -1px;
                            position: relative;
                            background: radial-gradient(#000 50%, #fff 50%);
                            content: "";
                            display: inline-block;
                            visibility: visible;
                            border: 1px solid #000;
                        }
                    }
                    .btns-container {
                        margin-top: 2.5rem;
                        @media (max-width: 576px) {
                            margin-top: 0;
                        }
                    }
                    .btn {
                        border-radius: 0.5rem !important;
                        border-color: $black !important;
                        line-height: 1rem;
                        max-height: 2.5rem;
                    }
                    .cancel-btn {
                        background-color: $white !important;
                        color: $black !important;
                        border: 2px solid $black;
                        .btn-text {
                            font-family: "BentonSansMedium";
                            font-size: 0.875rem;
                            color: $black;
                        }
                        @media (min-width: 576px) {
                            padding: 0.5rem 1.25rem !important;
                        }
                    }
                    .submit-btn {
                        background-color: $black !important;
                        .btn-text {
                            font-family: "BentonSansMedium";
                            font-size: 0.875rem;
                            background: linear-gradient(
                                90deg,
                                #ffd542 0%,
                                #e8a111 100%
                            );
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                        @media (min-width: 576px) {
                            padding: 0.5rem 3.25rem !important;
                        }
                    }
                }
            }
        }
    }
}
