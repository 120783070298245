@import "../../styles/variables";
.section-footer {
    font-family: Work Sans !important;
    margin-top: -1px;
    transition: none;
    .footer-content {
        padding-bottom: 1.75rem !important;
    }
    .name {
        font-size: 1rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    .heading {
        font-family: "BentonSansBold";
        font-size: 0.875rem;
        margin-bottom: 0.75rem;
        font-weight: 500;
    }

    .footer-menu {
        width: 70%;

        @media (max-width: 575px) {
            width: 100%;
        }
    }
    .items {
        @media (max-width: 575px) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .list-item {
        font-family: "BentonSansBook" !important;
        font-size: 0.875rem;
        text-decoration: none;
        margin-bottom: 1.25rem;
        &:hover {
            text-decoration: underline !important;
            color: $white !important;
        }
    }

    .copy {
        font-size: 0.875rem;
        font-family: "BentonSansBook";
    }

    .input {
        width: 160px;
        font-family: "BentonSansBook";
        font-size: 0.875rem !important;
        background: transparent;
        border: 1px solid var(--color-light-3);
        border-radius: 2px;
        padding: 0.475rem 2.75rem 0.475rem 0.625rem !important;
    }

    .app-btn--hollow--white {
        padding: 0.75rem !important;
        height: fit-content !important;
        width: fit-content !important;
        &:hover {
            background-color: unset;
        }
        .app-btn-text {
            font-family: "BentonSansBold" !important;
        }
    }

    .copyright {
        background-color: rgba(255, 255, 255, 0.1);
        font-size: 0.75rem;
        font-family: "BentonSansBook";
        a {
            &:hover {
                color: unset !important;
            }
        }
        @media (max-width: 575px) {
            font-size: 0.875rem;
        }
        padding: 1rem 0;
        .separator {
            color: rgba(255, 255, 255, 0.3);
            font-size: 10px;
        }
    }

    .icon-social {
        width: 24px;
        height: 24px;
        display: inline-block;
        top: 0;
        position: relative;
        transition: all 300ms;
        margin: 0 2rem 0 0;

        &.linkedin {
            background: url(../../images/linkedin-white.svg) no-repeat;
            background-position: center;
            background-size: contain;
        }

        &.facebook {
            background: url(../../images/facebook-white.svg) no-repeat;
            background-position: center;
            background-size: contain;
        }

        &.instagram {
            background: url(../../images/instagram-white.svg) no-repeat;
            background-position: center;
            background-size: contain;
        }
        // &.youtube {
        //   background: url(../../images/youtube.svg) no-repeat;
        //   background-position: center;
        //   background-size: contain;
        // }

        &:hover {
            cursor: pointer;
            top: -5px;
        }
    }

    .follow-us-links {
        padding-bottom: 7rem;
    }

    // .site-links {
    //     @media (max-width: 576px) {
    //         padding-top: 7rem;
    //     }
    // }
}
