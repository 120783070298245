@import "../../../styles/variables";

// BANNER IMAGE
// @media (min-width: $md) {}
.bg-BdarkBlack {
  background: $black;
  border-radius: 4px;
}

.bg-black {
  background: $black;
}

.PatternWhiteLarge,
.PatternWhiteSmall {
  position: absolute;
  pointer-events: none;
  top: 0;
}

.PatternWhiteSmall {
  width: auto;
  height: 100%;
  bottom: 0;
  // right : 0;
  right: -130px;
}

.PatternWhiteLarge {
  // width: 170px;
  // height: 200px;
  // left: 0;
  width: 240px;
  height: 170px;
  left: -60px;
  transform: rotate(180deg);
}
