@import "../../styles/variables";
.section-blogs {
  padding-top: 2.25rem !important;
  padding-bottom: 3rem !important;
  background: rgba(0, 0, 0, 0.9);
  .content {
    @media (max-width: 575px) {
      padding-left: 0;
      padding-right: 0;
    }
    .sub-heading {
      font-family: "BentonSansBold" !important;
      text-align: center !important;
      font-size: 2rem;
      padding-bottom: 2rem;
    }
    .card-deck {
      margin-left: 0;
      margin-right: 0;
      gap: 1.25rem;
    }
  }
  .carousel {
    .slide {
      text-align: unset;
    }
    .control-dots {
      .dot.selected {
        width: 2rem;
        border-radius: 25%;
        background-color: $white;
      }
    }
    .slider-wrapper {
      box-shadow: none;
      .slider {
        .slide {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
      }
    }
  }
  .card {
    background-color: #212121 !important;
    border: 2px solid #212121;
    margin: 0 !important;
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.12);
    border-radius: 1.25rem !important;
    padding: 0.25rem;
    .img-wrapper {
      border-radius: 1.25rem 1.25rem 0px 0px;
      overflow: hidden;
      max-height: 13rem;
      .card-img-top {
        min-height: 13rem;
        height: 100%;
      }
    }
    a {
      display: flex;
      flex-direction: column;
      flex: 1 auto;
      &:hover {
        color: unset !important;
      }
    }
    .card-body {
      padding: 1.25rem 0.75rem 1rem 0.75rem;
      display: flex;
      // grid-template-rows: 1fr 1fr;
      flex-direction: column;
      gap: 0.75rem;
    }

    .name {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      font-family: "BentonSansBold";
      @media (max-width: 575px) {
        font-size: 1.125rem;
        font-weight: 600;
      }
    }

    .desc {
      font-size: 0.75rem;
      font-weight: 400;
      font-family: "BentonSansBook";
      opacity: 0.8;
      line-height: 1.125rem;
    }

    .separator {
      width: 20px;
      height: 3px;
      background: linear-gradient(
        to right,
        var(--color-primary-1),
        var(--color-primary-2)
      );
    }

    .date {
      font-size: 0.625rem;
      font-weight: 400;
      letter-spacing: 0.1rem;
      font-family: "BentonSansBook";
      line-height: 0.93rem;
      @media (max-width: 575px) {
        font-size:0.75rem;
        font-weight: 500;
      }
    }

    .time {
      font-size: 0.625rem;
      font-weight: 400;
      letter-spacing: 0.1rem;
      font-family: "BentonSansBook";
      line-height: 0.93rem;
      @media (max-width: 575px) {
        font-size: 0.75rem;
        font-weight: 500;
      }
    }

    .card-footer {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      background: none;
      border: none;
    }
    @media (min-width: 576px) {
      &:hover {
        border: 2px solid rgba(255, 255, 255, 0.2);
        background: rgba(255, 255, 255, 0.1) !important;
        box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.12);
      }
    }
  }

  .blog {
    width: 33%;
    @media (max-width: 575px) {
      height: 100%;
    }
  }

  .summary {
    font-size: 1.125rem;
  }

  .name {
    font-family: "BentonSansBold";
    font-size: 1rem;
    line-height: 150%;
  }

  .desc {
    color: rgba(255, 255, 255, 0.5);
    font-family: "BentonSansBook" !important;
    font-size: 0.75rem;
  }

  .separator {
    width: 20px;
    height: 3px;
    margin-bottom: 0.75rem;
    background: linear-gradient(
      to right,
      var(--color-primary-1),
      var(--color-primary-2)
    );
  }

  .card-footer {
    padding: 2rem 0.75rem 1rem 0.75rem !important;
    background: none;
    border: none;
    font-family: "BentonSansBook";
    font-size: 10px;
  }
  .btn-wrapper {
    margin-top: 2.5rem;
    button {
      background-color: transparent;
      border-radius: 10px;
      border: 1.666px solid #e8a111;
      padding: 0.5rem 3rem;

      .btn-text {
        line-height: 150% !important;
        font-family: "BentonSansMedium";
        color: var(--color-primary-2);
      }
    }
  }
}
