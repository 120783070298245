// HIDE ARROWS

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

// NAV TABS
.nav-tabs {
    border-bottom: 1px solid $transparent !important;
}

// FORM
.form-label {
    font-weight: $fw100;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    color: $black;
    margin-bottom: 10px;
    display: block;
    letter-spacing: 0.02em;
}

.form-control,
.input-group-text,
.react-datepicker__input-container input,
.custom-select {
    border: none;
    border-bottom: 1px solid $black02;
    box-shadow: none;
    border-radius: 0;
    // padding: 12px 12px 12px 0;
    padding: 6px 12px 6px 0;
    display: block;
    text-align: left;
    height: auto;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: $black;
    font-weight: $fw600;
    background: $transparent;

    @media (max-width: $mmd) {
        font-size: 14px;
        line-height: 24px;
    }
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: $black !important;
    box-shadow: none !important;
    outline: none !important;
    background-position: right calc(0.375em + 0.6rem) center;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    background-position: right calc(0.375em + 0.6rem) center;
    border-color: unset;
    background-image: none;
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
    color: unset;
}

.custom-select:focus,
.form-control:focus,
.react-datepicker__input-container input {
    color: $black;
    background-color: $transparent;
    border-color: $orange;
    outline: 0;
    box-shadow: none;
}

.form-group {
    margin-bottom: 2rem;

    @media (max-width: $mmd) {
        margin-bottom: 1rem;
    }
}

.custom-select,
.react-datepicker__input-container input {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ced4da;
    background: url("../images/Common/downArrow.svg") no-repeat right center;
    padding-left: 0;
    width: 100%;
    // color: $orange;
    color: $black;
}

option {
    padding: 6px 12px !important;
    margin: 6px 12px !important;
    min-height: 30px !important;
}

.Footer-form {
    .input,
    .btn {
        width: 100% !important;
        border: 1px solid #ccc;
    }

    @media (max-width: 992px) {
        flex-direction: column;
        gap: 1rem;
    }
}
