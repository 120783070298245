@import "../../../styles/variables";

// BANNER IMAGE
.-bannerImage {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 1;
  object-fit: cover;

  @media (max-width: 992px) {
    height: 80vh;
  }
}

.-bannerImageBlock {
  height: 90vh;
  position: relative;

  &::after {
    background: black;
    content: "";
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0.2;
    pointer-events: none;

    @media (max-width: 992px) {
      height: 80vh;
    }
  }

  .pos-rel {
    z-index: 2;
  }
}

.-h60vh {
  height: 60vh;

  @media (max-width: 992px) {
    height: 90vh;
  }
}

.video {
  cursor: pointer;
}
