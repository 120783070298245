@import "../../../styles/variables";
.bloglist-banner-section {
    .banner-heading-content {
        font-family: Work Sans;
        font-style: normal;
        margin-bottom: 10%;
        margin-left: 12%;
        margin-right: 12%;
        max-width: 60%;
        position: absolute;
        bottom: 0;

        @media screen and (max-width: 575px) {
            max-width: 90%;
            bottom: 3rem;
        }

        .banner-heading-title {
            font-size: 4rem;
            font-weight: 900;
            color: #f6c02e;
            @media (max-width: 575px) {
                font-size: 1.75rem;
                font-family: $fontBai;
                color: $white;
                margin-bottom: 1rem;
            }
        }
        .banner-heading-desc {
            font-size: 1rem;
            @media (max-width: 575px) {
                font-size: 1rem;
                font-family: $fontBarlow;
                font-weight: 100;
            }
        }
    }
}
