@import "../../../../styles/variables";

.-blog-banner {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.-imageOther {
  height: 300px;
  width: 100%;
  object-fit: cover;
  margin: 1rem 0;
}

.--label {
  font-size: 14px;
  line-height: 19px;
  color: #898989;
  margin: 16px 0;
}

.-aside-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 0;
  background: #f7f7f7;
  border-radius: 4px;
  height: 100%;
  text-align: center;
}

.-blog-author {
  //
}

.-authorImage {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.-authorName {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: $black;
  margin: 12px 0;
}

.-department {
  font-size: 18px;
  line-height: 28px;
  color: #898989;
}

.blog-list-circle {
  list-style: square !important;
  padding-left: 1.5rem;
  color: #898989;
  
}
